import {useSearchParams} from 'react-router-dom'

export default function PaymentSuccess() {
  const [searchParams] = useSearchParams()
  const swipe_session = searchParams.get('session_id')
  return (
    <>
      <h1>Thanks for your order!</h1>
      <p>
        We appreciate your business! If you have any questions, please email
        <a href='mailto:orders@example.com'>orders@example.com</a>.
      </p>
    </>
  )
}
