import SignupForm from '../../components/forms/signupForm'
import LogoBar from '../../components/logoBar'
import FlexHeadFoot from '../../layouts/flexheadfoot'

export default function Signup(): React.ReactElement {
  return (
    <FlexHeadFoot header={<LogoBar />}>
      <SignupForm />
    </FlexHeadFoot>
  )
}
