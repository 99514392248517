import {QueryClient} from 'react-query'

export const queryClient = new QueryClient()
export const queryDefaults = {
  cacheTime: 15 * (60 * 1000),
  staleTime: 10 * (60 * 1000),
  retry: 1,
}
export const graphQueryDefaults = {
  cacheTime: 2 * 60 * (60 * 1000),
  staleTime: 1 * 60 * (60 * 1000),
  retry: 1,
}
