import axios from 'axios'
import React, {useEffect, useState} from 'react'

type Props = {
  resourceUrl: string
  resourceName: string
  children: React.ReactNode
}

export const ResourceLoader = ({resourceUrl, resourceName, children}: Props) => {
  const [state, setState] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const response = await axios.get(resourceUrl)
        setState(response.data)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [resourceUrl])

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {[resourceName]: state})
        }

        return child
      })}
    </>
  )
}
