export function emGetInitials(inputString: string, limit?: number): string {
  try {
    // Clean up punctuation in the input string
    inputString = (inputString || '').replace(/[^a-zA-Z ]/g, '')

    // Split the input string into an array of words
    const words = inputString.split(' ').filter((x) => x)

    // Initialize an empty string for storing the initials
    let initials = ''

    // Iterate through each word in the array
    for (let i = 0; i < words.length; i++) {
      // Get the first letter of the current word and make it uppercase
      const initial = words[i][0].toUpperCase()
      // Add the initial to the initials string
      initials += initial
    }
    if (limit && initials.length > limit) {
      initials = initials.substring(0, limit)
    }

    // Return the initials
    return initials
  } catch (error) {
    console.warn('getInitials error: ', error)
    return ''
  }
}

export function emInstanceOf<T>(object: any, fieldToCheck: any): object is T {
  return fieldToCheck in object
}

const sleep = (delayMilliseconds: number) =>
  new Promise((resolve: any) => setTimeout(resolve, delayMilliseconds))
export const emSleep = async (delayMilliseconds: number) => {
  await sleep(delayMilliseconds)
}

export const emToAbsoluteUrl = (pathname: string) => {
  console.log(process.env.PUBLIC_URL + pathname)
  return process.env.PUBLIC_URL + pathname
}

export type emRequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

export function emHasAllProperties(obj: any, props: any) {
  for (let i = 0; i < props.length; i++) {
    if (!(props[i] in obj)) return false
  }
  return true
}

export function emHasAnyProperties(obj: any, props: any) {
  for (let i = 0; i < props.length; i++) {
    if (props[i] in obj) return true
  }
  return false
}
