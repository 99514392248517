import {IMessage} from '../../../interfaces/iMessage'
type Props = {
  messages?: IMessage[]
}

export const SmallMessage = ({messages}: Props): React.ReactElement => {
  if (!messages) return <></>
  const {subject, dateCreated, viewDate, creatorName} = messages[0]

  return (
    <>
      <h3>
        {subject} - {creatorName}
      </h3>
      <p>{dateCreated?.toString()}</p>
      <p>{viewDate?.toString()}</p>
    </>
  )
}
