import toastr from 'toastr'
import errorize from './errorize'

function getMessageFrom(err: unknown, msg: string, args?: string[]) {
  const serverMsg = (err as any)?.response?.data?.message
  if (serverMsg && !serverMsg.toLowerCase().includes('internal server error')) {
    return serverMsg
  }
  let finalMsg = msg
  if (args) {
    for (let i = 0; i < args.length; i++) {
      finalMsg = finalMsg.split(`{arg${i + 1}}`).join(args[i])
    }
  }
  return finalMsg
}

export default function errorNotify(err: unknown, msg: string, args?: string[]) {
  toastr.error(getMessageFrom(err, msg, args))
  throw errorize(err)
}

export function errorNotifyReturn(err: unknown, msg: string, args?: string[]) {
  toastr.error(getMessageFrom(err, msg, args))
  return
}
