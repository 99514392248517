import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {grey} from '@mui/material/colors'
import {Link, useNavigate} from 'react-router-dom'
import LogoBar from '../../../components/logoBar'
import FlexHeadFoot from '../../../layouts/flexheadfoot'
import Button from '@mui/material/Button'

export const EmailVerificationSuccess = () => {
  const navigate = useNavigate()
  return (
    <FlexHeadFoot header={<LogoBar />}>
      <Box
        display='flex'
        flex={'1 1 auto'}
        flexDirection={'column'}
        alignContent={'center'}
        justifyContent={'center'}
        textAlign={'center'}
      >
        <Typography variant={'h2'}>Success</Typography>
        <Typography style={{marginTop: 5}} variant={'h6'} color={grey[700]}>
          Thanks for verifying your email, your all set!
        </Typography>
        <Typography style={{marginTop: '2em'}} variant={'h6'} color={grey[600]}>
          Your free account lets you get started right away but has limited storage, <br />
          future send dates, and message viewing capabilities.
        </Typography>
        <Typography style={{marginTop: '2em'}} variant={'h6'} color={grey[600]}>
          For the best experience, <Link to={'/upgrade-plans'}>upgrade</Link> your MessageUniverse
          account today
        </Typography>
      </Box>
      <Button
        size={'large'}
        sx={{width: '300px'}}
        variant={'contained'}
        onClick={() => navigate('/')}
      >
        {`Continue -->`}
      </Button>
    </FlexHeadFoot>
  )
}
function useEffect(arg0: () => void, arg1: any[]) {
  throw new Error('Function not implemented.')
}
