import MuiButton, {ButtonProps} from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import React from 'react'

const theme = createTheme({
  palette: {
    primary: {
      main: '#3EC1e8',
      light: '#96ddf2',
      dark: '#1ba3bb',
      contrastText: '#FFF',
    },
  },
})
declare module '@mui/material/styles' {
  interface Palette {
    primary: Palette['primary']
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    primary?: PaletteOptions['primary']
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primary: true
  }
}

type ButtonOptions = {
  btntext: string
}

export const CustButton1 = <C extends React.ElementType>(
  props: ButtonProps<C, {component?: C}> & ButtonOptions
) => {
  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <MuiButton {...props} fullWidth>
          {' '}
          {props.btntext}
        </MuiButton>{' '}
      </Stack>
    </ThemeProvider>
  )
}
