import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import grey from '@mui/material/colors/grey'
import red from '@mui/material/colors/red'
import {useNavigate} from 'react-router-dom'
import LogoBar from '../../../components/logoBar'
import FlexHeadFoot from '../../../layouts/flexheadfoot'

export const EmailVerificationFail = () => {
  const navigate = useNavigate()

  return (
    <FlexHeadFoot header={<LogoBar />}>
      <Box
        display='flex'
        flex={'1 1 auto'}
        flexDirection={'column'}
        alignContent={'center'}
        justifyContent={'center'}
        textAlign={'center'}
      >
        <Typography variant={'h2'}>Error</Typography>
        <Typography style={{marginTop: 5}} variant={'h6'} color={red[500]}>
          An Error Occurred verifying your email
        </Typography>
        <Typography style={{marginTop: '2em'}} variant={'h6'} color={grey[600]}>
          Email Verification failed, try again later
        </Typography>

        <Box m={5}>
          <Button onClick={() => navigate('/signup')}>Signup</Button>
        </Box>
      </Box>
    </FlexHeadFoot>
  )
}
