import {useCallback, useState} from 'react'
import {apiHost} from '../config/apiConfig'
import {userFormData} from '../interfaces/shared'
import {PostDataOptions, PostDataResponse, postData} from './api/usePostDataFetch'
type signUpResponse = {
  token: string
}

export default function usePostGeneric<T>(): [
  (path: string, body: any, options?: PostDataOptions<T>) => Promise<void>,
  PostDataResponse<T>
] {
  const [data, setData] = useState<T | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()

  const execPostGeneric = useCallback(
    async (path: string, body: userFormData, options?: PostDataOptions<T>) => {
      const route = apiHost + path
      setLoading(true)
      postData<T>(route, JSON.stringify(body))
        .then((response: T) => {
          return response
        })
        .then((response) => {
          setData(response)
          setLoading(false)
          options?.onComplete?.(response)
        })
        .catch((e) => {
          setError(e)
          setLoading(false)
          options?.onError?.(e)
        })
    },
    []
  )

  return [execPostGeneric, {data, loading, error, setData}]
}
