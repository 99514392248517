import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {grey} from '@mui/material/colors'
import {useNavigate} from 'react-router-dom'
import LogoBar from '../../../components/logoBar'
import FlexHeadFoot from '../../../layouts/flexheadfoot'

export const PasswordResetSuccess = () => {
  const navigate = useNavigate()

  return (
    <FlexHeadFoot header={<LogoBar />}>
      <Box
        display='flex'
        flex={'1 1 auto'}
        flexDirection={'column'}
        alignContent={'center'}
        justifyContent={'center'}
        textAlign={'center'}
      >
        <Typography variant={'h2'}>Success</Typography>
        <Typography style={{marginTop: 5}} variant={'h6'} color={grey[700]}>
          Your password has been reset
        </Typography>
        <Typography style={{marginTop: '2em'}} variant={'h6'} color={grey[600]}>
          You can now login with your new password
        </Typography>

        <Box m={5}>
          <Button onClick={() => navigate('/login')}>Login</Button>
        </Box>
      </Box>
    </FlexHeadFoot>
  )
}
