import {grey} from '@mui/material/colors'
import React from 'react'
import {ResourceLoader} from '../../components/containers/resourceLoader'
import {OutboxLarge} from '../../components/layouts/message/outboxLarge'
import {SmallMessage} from '../../components/layouts/message/smallMessage'
import {hocBasicModal} from '../../components/modals/hocBasicModal'
import LandingBar from '../../layouts/LandingBar'
import ContentMiddle from '../../layouts/contentMiddle'
import {SplitScreen} from '../../layouts/splitScreen'
import {apiHost} from 'config/apiConfig'

const nestedObject = {
  a: 1,
  b: {
    b1: 4,
    b2: {
      b23: 'Hello',
    },
    b3: {
      b31: {
        message: 'Hi',
      },
      b32: {
        message: 'Hi',
      },
    },
  },
  c: {
    c1: 2,
    c2: 3,
  },
}

const LeftSide = () => {
  return (
    <ul>
      <li>listItem 1</li>
      <li>listItem 2</li>
      <li>listItem 3</li>
      <li>listItem 4</li>
    </ul>
  )
}

const RightSide = () => {
  //modal
  const HocModalSmallMessage = hocBasicModal(SmallMessage)
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)

  return (
    <ResourceLoader resourceUrl={`${apiHost}/api/mockdata/messages`} resourceName={'messages'}>
      <HocModalSmallMessage
        modalOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      ></HocModalSmallMessage>
      <button onClick={() => setModalOpen(!modalOpen)}>
        {modalOpen ? 'Hide Message' : 'Show Message'}
      </button>

      <OutboxLarge />
    </ResourceLoader>
  )
}

export default function Messages(): React.ReactElement {
  const hideIT: boolean = process.env.REACT_APP_HIDE_IT === 'TRUE' ? true : false
  return (
    <ContentMiddle header={<LandingBar hideIT={hideIT} />}>
      <SplitScreen
        leftFlex={'0 0 10%'}
        rightFlex={'1 1 auto'}
        rightBgColor={grey[50]}
        rightColor={'white'}
        leftBgColor={grey[800]}
        leftColor={'white'}
      >
        <LeftSide />
        <RightSide />
      </SplitScreen>
    </ContentMiddle>
  )
}
