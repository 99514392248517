import React from 'react'
import LandingBar from './LandingBar'
import Footer from './footer'

const MU_MAIN = 'MU_MAIN'

export type Props = React.PropsWithChildren<{
  header?: React.ReactNode
  footer?: React.ReactNode
}>

export default function LandingLayout({children, header, footer}: Props): React.ReactElement {
  const hideIT: boolean = process.env.REACT_APP_HIDE_IT === 'TRUE' ? true : false
  return (
    <>
      <div style={{backgroundColor: '#232323'}}>
        <LandingBar hideIT={hideIT} />
        <div style={{borderTop: '1px solid #3ec1e8'}}>
          <main
            style={{
              flexGrow: 1,
              minHeight: '100vh',
              overflow: 'auto',
              backgroundColor: '#262626',
              maxWidth: '1536px',
              margin: 'auto',
            }}
            id={MU_MAIN}
          >
            <div> {children}</div>
          </main>
          {hideIT ? <></> : <Footer />}
        </div>
      </div>
    </>
  )
}
