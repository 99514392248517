import {AuthHeaders} from 'auth/AuthProvider'
import axios, {AxiosHeaders} from 'axios'
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'

export interface GetDataResponse<TData = unknown> {
  loading: boolean
  error: Error | undefined
  data: TData | undefined
  setData: Dispatch<SetStateAction<TData | undefined>>
}

export interface GetDataOptions<TResponse = unknown> {
  onComplete?: (res: TResponse) => void
  onError?: (e: any) => void
  excludeAuthHeaders?: boolean
  loading?: boolean
  method?: 'get' | 'delete' | 'post'
}

export async function getDataAxios<TResponse = unknown>(
  path: string,
  method: 'get' | 'delete' | 'post' = 'get'
): Promise<TResponse> {
  switch (method) {
    case 'get': {
      const res = await axios.get(path)
      return res.data as unknown as TResponse
    }
    case 'delete': {
      const res = await axios.delete(path)
      return res.data as unknown as TResponse
    }
    case 'post': {
      const res = await axios.post(path)
      return res.data as unknown as TResponse
    }
  }
}

const useGetDataAxios = <TResponse = unknown>(
  path: string,
  {
    lazyLoad = false,
  }: {
    lazyLoad?: boolean
  }
): [(options?: GetDataOptions<TResponse>) => Promise<void>, GetDataResponse<TResponse>] => {
  const [data, setData] = useState<TResponse | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()

  const execGetData = useCallback(
    async (options?: GetDataOptions<TResponse>) => {
      setLoading(true)

      try {
        getDataAxios(path, options && options.method ? options.method : 'get')
          .then((response) => {
            setData(response as TResponse)
            setLoading(false)
            options?.onComplete?.(response as TResponse)
          })
          .catch((e) => {
            setData(undefined)
            setLoading(false)
            setError(e)
            options?.onError?.(e)
          })
      } catch (e) {
        setError(e as Error)
        setLoading(false)
        options?.onError?.(e)
      }
    },
    [path]
  )

  useEffect(() => {
    if (lazyLoad) {
      return
    }
    execGetData()
  }, [lazyLoad, execGetData])

  return [execGetData, {data, loading, error, setData}]
}
