import {usePostHookMutationAxios} from 'hooks/usePostHookMutationAxios'
import {userFormData} from 'interfaces/shared'
import {jwtDecode} from 'jwt-decode'
import {useState} from 'react'
import toastr from 'toastr'

//for auth, I'll need to set the auth token in local session, then remove auth in session or add redux

export const useAuth = () => {
  const login = usePostHookMutationAxios<{token: string}>(`auth/login`)
  const signup = usePostHookMutationAxios<{token: string}>(`auth/signup`)
  const [token, setTokenInternal] = useState<any>(() => {
    return localStorage.getItem('token')
  })

  const getPayloadFromToken = (token: any) => {
    if (token) {
      const decoded = jwtDecode(token)
      console.log('DECODED:' + decoded)
      //const encodedPayload = token.split('.')[1]
      return decoded
    }
    return token
  }

  const [user, setUser] = useState(() => {
    if (!token) return null
    return getPayloadFromToken(token)
  })

  const setToken = (newToken?: string) => {
    if (newToken) {
      localStorage.setItem('token', newToken)
      setTokenInternal(newToken)
      setUser(getPayloadFromToken(newToken))
    } else {
      localStorage.removeItem('token')
      setTokenInternal(undefined)
      setUser(undefined)
    }
  }

  const loginAuth = async (data?: userFormData, googleToken?: string, isLogin?: true) => {
    let retval: {token: string} | undefined
    if (googleToken) {
      console.log('mu token, google auth call:' + googleToken)
      setToken(googleToken)
    } else {
      if (isLogin) {
        retval = await login.mutateAsync({
          body: data,
          key: 'login',
        })
      } else {
        retval = await signup.mutateAsync({
          body: data,
          key: 'login',
        })
      }
      if (retval?.token) {
        setToken(retval.token)
      } else {
        toastr.error('bad request')
      }
    }
  }

  const logoutAuth = () => {
    setToken()
  }

  return [user, loginAuth, logoutAuth, setToken]
}
