import {useAuthProvider} from 'auth/AuthProvider'
import {Navigate} from 'react-router-dom'

const PrivateRoute = ({children}: {children: React.ReactElement}) => {
  const {currentUser} = useAuthProvider()
  if (!currentUser) return <Navigate to={'/login'} />
  return children
}

export default PrivateRoute
