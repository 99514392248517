import React from 'react'
import {apiHost} from '../../config/apiConfig'
import LandingLayout from '../../layouts/landing'
import styled from 'styled-components'
import {device} from 'layouts/breakpoint'
const FrameWrapper = styled.div`
  @media only screen (min-device-width: 1280px) :padding:5em;
  @media only screen (min-device-width: 1025px) :padding:5em;
  @media only screen (min-device-width: 768px) and (max-device-width: 1024px) :padding:5em;
  @media only screen (max-device-width: 480px) : padding:4em;
  @media (max-width: 540px) : padding:0em, padding-top:70px;
  display: flex;
  justify-content: center;
  color: #bf4f74;
  flex: 1;
  width: 90%;
  height: 80vh;
  &:video: {
    width: 100%;
  }
`

const BlogCardsContainer = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  &:video: { display:flex, flex-basis:90%, height:100vh;};
  @media ${device.md} {
    flex-direction: column;
  };
`

export default function Landing(): React.ReactElement {
  //for this landing page only, body tag should hae hidden overflow for video
  document.body.style.overflow = 'hidden'
  const apiSrc = 'https://api.messageuniverse.com/mu9.mp4'
  const hideIT: boolean = process.env.REACT_APP_HIDE_IT === 'TRUE' ? true : false
  return (
    <LandingLayout>
      {hideIT ? (
        <></>
      ) : (
        <FrameWrapper>
          <video
            loop
            controls
            autoPlay
            muted
            preload={'auto'}
            style={{backgroundColor: '#006655 !important'}}
          >
            <source src={apiSrc} type='video/mp4' />
          </video>
        </FrameWrapper>
      )}
    </LandingLayout>
  )
}
