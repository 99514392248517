import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, Link} from 'react-router-dom'
import * as yup from 'yup'
import {SubmitButton} from '../../components/submitButton'
import useYupValidationResolver from '../../hooks/app/yupValidator'
import {userFormData} from '../../interfaces/shared'
import {GoogleLogin, useGoogleLogin, googleLogout, GoogleOAuthProvider} from '@react-oauth/google'
import {grey} from '@mui/material/colors'
import axios from 'axios'
import {useAuthProvider} from 'auth/AuthProvider'
import {useAuth} from 'auth/useAuth'
import {CustButton1} from 'components/customButton1'
import LinearProgress from '@mui/material/LinearProgress'
import {iGoogleUser} from 'interfaces/iGoogleUser'
import {apiHost} from 'config/apiConfig'

const validationSchema = yup.object({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
})

export default function LoginForm(): React.ReactElement {
  const [, loginAuth] = useAuth()
  const [loadingAuth, setLoadingAuth] = useState<boolean>(false)
  const {currentUser, setCurrentUser} = useAuthProvider()
  const navigate = useNavigate()

  const resolver = useYupValidationResolver(validationSchema)
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<userFormData>({resolver})

  const handleMuLogin = handleSubmit(async (data) => {
    if (data) {
      setLoadingAuth(true)
      await loginAuth(data, undefined, true)
      setLoadingAuth(false)
      navigate('/')
      window.location.reload()
    }
  })

  //Password fields
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const responseSuccessGoogle = (response: any) => {
    setLoadingAuth(true)
    axios({
      method: 'POST',
      url: `${apiHost}/api/auth/googlelogin`,
      data: {tokenId: response.credential},
    })
      .then(async (apiResponse: any) => {
        //now set front end auth
        console.log(apiResponse)
        console.log(apiResponse.data)
        console.log(apiResponse.data.token)
        await loginAuth(null, apiResponse.data.token, true)
        setLoadingAuth(false)
        //navigate('/')
        //window.location.reload()
      })
      .catch((err) => {
        console.log(err)
        setLoadingAuth(false)
      })
  }

  const responseErrorGoogle = (response: any) => {
    console.log(response)
  }

  const googleLogOut = () => {
    googleLogout()
    setCurrentUser(null)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000000',
      }}
    >
      {loadingAuth ? (
        <Box display={'flex'} flexDirection={'column'}>
          <Typography color={grey[50]} variant={'h4'}>
            Logging in....
          </Typography>
          <LinearProgress variant={'indeterminate'} />
        </Box>
      ) : (
        <Grid
          item
          style={{
            width: '400px',
            backgroundColor: '#eeeeee',
            paddingLeft: '1em',
            paddingRight: '2em',
            paddingTop: '2em',
            paddingBottom: '1em',
            borderRadius: '4px',
          }}
        >
          <Grid item xs={12} mb={4}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant={'h5'}>Login</Typography>
              <Button onClick={() => navigate('/')}>
                <Typography variant={'body1'}>Cancel</Typography>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} justifyContent={'center'}>
              {currentUser ? (
                <div>
                  <img src={currentUser.picture} alt='user image' />
                  <h3>User is already Logged in</h3>
                  <p>Name: {currentUser.name}</p>
                  <p>Email Address: {currentUser.email}</p>
                  <br />
                  <br />
                  <button onClick={googleLogOut}>Log out of google</button>
                </div>
              ) : (
                // <button
                //   onClick={() => {
                //     setLoadingAuth(true), handleGoogleLogin()
                //   }}
                // >
                //   Sign in with Google 🚀{' '}
                // </button>

                //@ts-ignore
                <GoogleLogin
                  onSuccess={responseSuccessGoogle}
                  //@ts-ignore
                  onError={responseErrorGoogle}
                  cookiePolicy={'single_host_origin'}
                />
              )}
            </Box>
          </Grid>
          <Typography variant={'body1'} color={grey[600]} justifyContent={'center'} mt={2}>
            Don't have a google account, use your message universe login, dont have one of those
            either, <Link to={'/signup'}>click here </Link>to create one
          </Typography>
          <Grid item xs={12}>
            <TextField
              error={errors?.email ? true : false}
              sx={{
                backgroundColor: '#FFFFFF',
                borderRadius: '4px',
                margin: '.5em',
                marginTop: '2em',
              }}
              fullWidth
              id='email'
              label='Email'
              {...register('email')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              sx={{
                width: '100%',
                backgroundColor: '#FFFFFF',
                m: 1,
              }}
              variant='outlined'
            >
              <InputLabel htmlFor='password'>Password</InputLabel>
              <OutlinedInput
                id='password'
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                      tabIndex={-1}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Password'
                error={errors?.password ? true : false}
                {...register('password')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <CustButton1
                btntext={'Login'}
                variant='contained'
                color={'primary'}
                onClick={handleMuLogin}
                sx={{mr: 1, ml: 1, mt: 1, p: 1, fontSize: '2em'}}
              />
            </FormControl>
          </Grid>
          <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
            <Button variant={'text'} onClick={() => navigate('/forgot-password')}>
              Forgot Username or Password
            </Button>
          </Box>
        </Grid>
      )}
    </Box>
  )
}
