import {useQuery} from 'react-query'
import {apiEndpoint} from '../config/apiConfig'
import {queryDefaults} from './api/react-query-defaults'
import {postDataAxios} from './api/usePostDataAxios'
import errorNotify from './api/utils/errorNotify'
import {getDataAxios} from './api/useGetDataAxios'
import {AxiosHeaders} from 'axios'
//@ts-ignore
export function useGetHookQueryAxios<T>(route: string, key?: string | string[], errMsg?: string) {
  return useQuery({
    queryKey: key,
    queryFn: async () => {
      try {
        const res = await getDataAxios<T>(apiEndpoint(route))
        return res
      } catch (err) {
        errorNotify(err, errMsg ? errMsg : '')
      }
    },
    cacheTime: queryDefaults.cacheTime,
    staleTime: queryDefaults.staleTime,
    retry: queryDefaults.retry,
  })
}
