const today = new Date()

export const DateFormatter = (date: Date = today, format?: string): string => {
  if (format) {
    const map: any = {
      mm: date.getMonth() + 1,
      dd: date.getDate(),
      yy: date.getFullYear().toString().slice(-2),
      yyyy: date.getFullYear(),
    }
    return format.replace(/mm|dd|yy|yyy/gi, (matched: string) => map[matched])
  }

  // let options = {
  //   weekday: "long",
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // } as const;

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }

  return date.toLocaleDateString('en-us', options)
  //return new Intl.DateTimeFormat("en-us", options).format(new Date(sourceDate));
}
