import {ReactNode, createContext, useContext, useEffect, useMemo, useState} from 'react'
import {useAuth} from './useAuth'
import axios, {AxiosRequestConfig, InternalAxiosRequestConfig} from 'axios'

type AuthContextProps = {
  currentUser: any
  setCurrentUser: (user: any) => void
  logout: () => void
}

export interface AuthHeaders {
  sessionId?: string
  Authorization?: string
}

const initAuthContextPropsState = {
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuthProvider = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({children}: {children: ReactNode}) => {
  const [user, loginAuth, logoutAuth, setToken] = useAuth()
  const [currentUser, setStateCurrentUser] = useState<any>(user)

  const logout = () => {
    logoutAuth()
    setCurrentUser(undefined)
  }

  const setCurrentUser = (user: any) => {
    setStateCurrentUser(user)
  }
  useMemo(() => {
    if (user) {
      setCurrentUser(user)
      axios.interceptors.request.use(
        async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
          if (user) {
            config.headers.authorization = `JWT ${localStorage.getItem('token')}`
          }
          return config
        }
      )
    }
  }, [user])

  // useEffect(() => {
  //   if (user) {
  //     axios.interceptors.request.use(
  //       async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  //         if (user) {
  //           config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  //         }
  //         return config
  //       }
  //     )
  //   }
  // }, [user])

  return (
    <AuthContext.Provider value={{currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

export {AuthProvider, useAuthProvider}
