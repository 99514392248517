import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import * as yup from 'yup'
import {SubmitButton} from '../../components/submitButton'
import useYupValidationResolver from '../../hooks/app/yupValidator'
import usePutGeneric from '../../hooks/usePutGeneric'
import {userFormData} from '../../interfaces/shared'

const validationSchema = yup.object({
  password: yup.string().required('New Password is required'),
  confirmPassword: yup.string().test('passwords-match', 'Passwords must match', function (value) {
    return this.parent.password === value
  }),
})

export default function ResetPasswordForm(): React.ReactElement {
  const info = window.navigator.userAgent
  const navigate = useNavigate()
  const [exec, {data, loading, error}] = usePutGeneric<any>()
  const {resetCode} = useParams()
  const resolver = useYupValidationResolver(validationSchema)
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<userFormData>({resolver})

  //submit rns the function here, hook is called and sets data
  //need an effet on retData to set a token and navigate
  const onSubmit = handleSubmit(async (data) => {
    if (data) {
      exec(
        `/api/auth/users/${resetCode}/reset-password`,
        {newPassword: data.confirmPassword},
        {
          onComplete: () => navigate('/reset-success'),
          onError: () => navigate('/reset-fail'),
        }
      )
    }
  })

  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show)
  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  //route must have a code or cannot continue
  if (!resetCode) navigate('/home')

  return (
    <Box
      component='form'
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000000',
      }}
      autoComplete='off'
      onSubmit={onSubmit}
    >
      <Grid
        item
        style={{
          width: '400px',
          backgroundColor: '#eeeeee',
          paddingLeft: '1em',
          paddingRight: '2em',
          paddingTop: '2em',
          paddingBottom: '3em',
          borderRadius: '4px',
        }}
      >
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant={'h5'}>Reset Password</Typography>
            <Button onClick={() => navigate('/')}>
              <Typography variant={'body1'}>Cancel</Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              m: 1,
            }}
            variant='outlined'
          >
            <InputLabel htmlFor='password'>New Password</InputLabel>
            <OutlinedInput
              id='password'
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                    tabIndex={-1}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='Password'
              error={errors?.password ? true : false}
              {...register('password')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              m: 1,
            }}
            variant='outlined'
          >
            <InputLabel htmlFor='retypepassword'>Confirm New Password</InputLabel>
            <OutlinedInput
              id='confirmPassword'
              type={showConfirmPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle confirm password visibility'
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge='end'
                    tabIndex={-1}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='Confirm password'
              error={errors?.confirmPassword ? true : false}
              {...register('confirmPassword')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <SubmitButton
              btntext={'Reset Password'}
              type='submit'
              variant='contained'
              color={'primary'}
              sx={{mr: 1, ml: 1, mt: 1, p: 1, fontSize: '1em'}}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}
