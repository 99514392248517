import React, {useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Button from '@mui/material/Button'

export default function Account() {
  const navigate = useNavigate()
  const [subscriptions, setSubscriptions] = useState<any>([])

  useEffect(() => {
    const fetchData = async () => {
      const {subscriptions} = await fetch('api/subscriptions').then((r) => r.json())

      setSubscriptions(subscriptions.data)
    }
    fetchData()
  }, [])

  if (!subscriptions) {
    return ''
  }

  return (
    <div>
      <h1>Account</h1>

      <a href='/prices'>Add a subscription</a>
      <a href='/'>Restart demo</a>

      <h2>Subscriptions</h2>

      <div id='subscriptions'>
        {subscriptions.map((s: any) => {
          return (
            <section>
              <hr />
              <h4>
                <a href={`https://dashboard.stripe.com/test/subscriptions/${subscriptions.id}`}>
                  {s.id}
                </a>
              </h4>

              <p>Status: {s.status}</p>

              <p>Card last4: {s.default_payment_method?.card?.last4}</p>

              <p>Current period end: {new Date(s.current_period_end * 1000).toString()}</p>

              {/* <Link to={{pathname: '/change-plan', state: {subscription: subscription.id }}}>Change plan</Link><br /> */}
              {/* <Link to={{pathname: '/cancel', state: {subscription: s.id }}}>Cancel</Link> */}
              <Button onClick={() => navigate('/cancel', {state: {subscriptio: s.id}})}>
                Cancel
              </Button>
            </section>
          )
          //   <AccountSubscription key={s.id} subscription={s} />
        })}
      </div>
    </div>
  )
}
