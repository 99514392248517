import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LandingBar from 'layouts/LandingBar'
import {useNavigate} from 'react-router-dom'
import toastr from 'toastr'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import blue from '@mui/material/colors/blue'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import grey from '@mui/material/colors/grey'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import * as React from 'react'
import {styled} from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
  CURRENTGIGCOST,
  PriceCatalog,
  ShowInflationCostsPerGigabyte,
  ShowInflationCostsPerMegabyte,
  getInflationOverYerPerGigaByte,
} from 'utils/pricing'
import usePostGeneric from 'hooks/usePostGeneric'
import PaymentCheckoutPlan from '../components/payment/PaymentCheckoutPlan'
import {useGetHookQueryAxios} from 'hooks/useGetHookQueryAxios'
import {apiEndpoint} from 'config/apiConfig'
import {iProduct} from 'interfaces/iProduct'

export default function MuPlans() {
  const productSubscriptionsHook = useGetHookQueryAxios<iProduct[]>('products/subscriptions')

  const navigate = useNavigate()

  const hideIT: boolean = process.env.REACT_APP_HIDE_IT === 'TRUE' ? true : false

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  function createData(
    name: string,
    storage: number,
    year: string,
    price: string,
    cart: React.ReactElement
  ) {
    return {name, storage, year, price, cart}
  }

  const rows = React.useMemo(() => {
    const r: any[] = []
    if (productSubscriptionsHook.data && Array.isArray(productSubscriptionsHook.data)) {
      productSubscriptionsHook.data?.map((p) =>
        r.push(
          createData(
            p.product_name,
            p.product_storage_mb as unknown as number,
            p.product_year,
            p.product_price.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
            <Button
              color={'success'}
              onClick={() => navigate('/signup')}
              variant={'contained'}
              size='small'
              sx={{
                color: 'black',
                textTransform: 'none',
                fontWeight: 400,
                width: '250px',
                background: '#ffd814',
                borderColor: '#fcd200',
                borderRadius: '100px',
              }}
            >
              Select
            </Button>
          )
        )
      )
    }
    return r
  }, [productSubscriptionsHook])

  return (
    <Box>
      <Box sx={{minHeight: 'calc(100vh - 70px'}}>
        <LandingBar hideIT={hideIT} />
      </Box>
      <Box maxWidth={'1320px'} margin={'auto'}>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 700}} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Subscription Package Extensions</StyledTableCell>
                <StyledTableCell align='right'>Storage</StyledTableCell>
                <StyledTableCell align='right'>Sending Year(s)</StyledTableCell>
                <StyledTableCell align='right'>Price</StyledTableCell>
                <StyledTableCell align='right'>Cart</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component='th' scope='row'>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align='right'>{row.storage}</StyledTableCell>
                  <StyledTableCell align='right'>{row.year}</StyledTableCell>
                  <StyledTableCell align='right'>{row.price}</StyledTableCell>
                  <StyledTableCell align='right'>{row.cart}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <PaymentCheckoutPlan plan={{items: []}} />
      {/* {productsSubHook.data?.map((p) => {
        return <Typography>{p.product_name}</Typography>
      })} */}
    </Box>
  )
}
