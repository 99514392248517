import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import LogoBar from '../../components/logoBar'
import FlexHeadFoot from '../../layouts/flexheadfoot'
import Button from '@mui/material/Button'

export default function VerifyEmail() {
  const navigate = useNavigate()
  const handleReload = () => {
    navigate('/')
    window.location.reload()
  }
  return (
    <FlexHeadFoot header={<LogoBar />}>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000000',
        }}
      >
        <Grid
          item
          style={{
            width: '50em',
            backgroundColor: '#eeeeee',
            paddingLeft: '1em',
            paddingRight: '2em',
            paddingTop: '2em',
            paddingBottom: '3em',
            borderRadius: '4px',
          }}
        >
          <Grid item xs={12}>
            <Typography variant={'h4'}>Thanks for Signing Up!</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h6'}>
              Please verify your email by:
              <br />
              Checking your email and following the link provided by MessageUniverse
            </Typography>
          </Grid>
          <Button onClick={handleReload}>Continue</Button>
        </Grid>
      </Box>
    </FlexHeadFoot>
  )
}
