import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

export default function Cancel() {
  const navigate = useNavigate()
  const location = useLocation()
  const [cancelled, setCancelled] = useState(false)

  const handleClick = async (e: any) => {
    e.preventDefault()

    await fetch('api/cancel-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionId: location.state.subscription,
      }),
    })

    setCancelled(true)
  }

  if (cancelled) {
    navigate('/account')
  }

  return (
    <div>
      <h1>Cancel</h1>
      <button onClick={handleClick}>Cancel</button>
    </div>
  )
}
