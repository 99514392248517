//goal, find price per gb of data with inflation of US currency then consider deflation of storage cost
//current cost per GB in 2024 approx .023 for highest tier (First 50TB / month for s3 storage)
//9.99/mo for 2TB on Google Drive or 2TB for $100/year
//so their is the cost for MU to buy an consumer price which is expanded for maintenace
import * as React from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import {blue} from '@mui/material/colors'
import moment from 'moment'

export default function SandBox() {
  const today = new Date()
  const percentInflation = 1 + 2.5 / 100
  const percentMuMaintExpansion = 0.035

  const getInflationOverYer = (year: number, currentGigCost: number, gigsToBuy: number) => {
    let money = currentGigCost + percentMuMaintExpansion * year
    let num = 0
    let total = 0

    const percent = percentInflation

    for (let y = 1; y <= Math.ceil(year); y++) {
      num = num + 1
      money = money * percent
      if (y > year) {
        // year 8
        // the sum will only increas .373 of year 8
        money *= 1 - (y - year)
        console.log(`Calculating ${1 - (y - year)} of year ${y}`)
      }

      //  increment total
      total += money
      console.log(
        'IN FUNCTON CALC - Year ' +
          num +
          ' - ' +
          money.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
      )
      // other stuff
    }
    // take the money originally set from currentGigCost and multiple number of years inflation
    const retval = money * gigsToBuy
    console.log(
      `IN FUNCTON CALC - Total: after ${year} years:`,
      total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    )

    return retval
  }

  const [gig, setGig] = React.useState<number>()
  const [price, setPrice] = React.useState<number>()
  const [seletedNumberYears, setSeletedNumberYears] = React.useState<number>()

  const handleChange = (event: SelectChangeEvent) => {
    const gigs = event.target.value as unknown as number
    setGig(gigs)
  }
  const handleChangeYear = (event: SelectChangeEvent) => {
    const selectedYear = event.target.value as unknown as number
    const numbYears = selectedYear - today.getFullYear()
    setSeletedNumberYears(numbYears)
  }

  React.useEffect(() => {
    if (seletedNumberYears && gig) {
      const price = getInflationOverYer(seletedNumberYears, 0.023, gig)
      setPrice(price)
    }
  }, [seletedNumberYears, gig])

  const currentMoment = moment()
  const endMoment = moment().add(100, 'year')
  const availableYears = []
  while (currentMoment.isBefore(endMoment, 'year')) {
    //console.log(`Loop at ${currentMoment.format('YYYY')}`)
    currentMoment.add(1, 'year')
    availableYears.push(currentMoment.format('YYYY'))
  }
  console.log(availableYears)

  return (
    <>
      Total for selectd Gigabytes
      <Box sx={{maxWidth: 300}} p={3}>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>Select Future Year to Send To</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={seletedNumberYears as unknown as string}
            label='Age'
            onChange={handleChangeYear}
          >
            {availableYears.map((year) => {
              return <MenuItem value={year}>Send until {year}</MenuItem>
            })}
          </Select>
        </FormControl>
        {seletedNumberYears && (
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Select Storage Amount</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={gig as unknown as string}
              label='Age'
              onChange={handleChange}
            >
              <MenuItem value={1}>1 (gigabyte)</MenuItem>
              <MenuItem value={5}>5 (gigabytes)</MenuItem>
              <MenuItem value={10}>10 (gigabytes)</MenuItem>
              <MenuItem value={25}>25 (gigabytes)</MenuItem>
              <MenuItem value={50}>50 (gigabytes)</MenuItem>
              <MenuItem value={100}>100 (gigabytes)</MenuItem>
              <MenuItem value={200}>200 (gigabytes)</MenuItem>
              <MenuItem value={500}>500 (gigabytes)</MenuItem>
              <MenuItem value={1000}>1 (terabyte)</MenuItem>
              <MenuItem value={2000}>2 (terabytes)</MenuItem>
              <MenuItem value={3000}>3 (terabytes)</MenuItem>
              <MenuItem value={4000}>4 (terabytes)</MenuItem>
              <MenuItem value={5000}>5 (terabytes)</MenuItem>
            </Select>
          </FormControl>
        )}
      </Box>
      <Typography>
        Extension Package: <br />
        Add {gig} GBs in storage for messages sent in{' '}
        {moment().add(seletedNumberYears, 'year').format('YYYY')}
      </Typography>
      <Typography color={blue[500]} variant={'h6'}>
        Price:{' '}
        {price?.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}
      </Typography>
      <Typography color={blue[500]} variant={'h6'}>
        Number of years selected: {seletedNumberYears}
      </Typography>
    </>
  )
}
