import Button from '@mui/material/Button'
import usePostGeneric from 'hooks/usePostGeneric'
import toastr from 'toastr'

export default function PaymentCheckoutPlan({plan}: {plan: any}) {
  const [exec, {data, loading, error}] = usePostGeneric<any>()

  const handleCheckout = async () => {
    //put request with email, api sends email with code, this navigates to a user message then goes home, or it errors
    exec(`/api/pay/checkout`, plan, {
      onComplete: (res) => (window.location.href = res),
      onError: (error: any) => {
        toastr.error('An error occurred on checkout')
      },
    })
  }

  return (
    <div className='App'>
      <Button onClick={handleCheckout}>Checkout</Button>
    </div>
  )
}
