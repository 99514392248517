import {createRoot} from 'react-dom/client'
import {QueryClient, QueryClientProvider} from 'react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'App.css'
import {BrowserRouter, Route, Routes, To, useLocation, useNavigate} from 'react-router-dom'
import './index.css'
import AppRoutes from 'AppRoutes'
import {AuthProvider} from 'auth/AuthProvider'

// Wrapping setup in a function call so we can use await() at the top level.
// async function load() {
//   const {PUBLIC_URL} = process.env
//   const url = new URL(window.location.href)
//   if (
//     url.pathname.includes('reset-password') ||
//     url.pathname.includes('verify-email') ||
//     url.pathname === '/' ||
//     url.pathname === '/login' ||
//     url.pathname === '/signup' ||
//     url.pathname === '/privacy' ||
//     url.pathname === '/health' ||
//     url.pathname === '/please-verify' ||
//     url.pathname === '/forgot-message' ||
//     url.pathname === '/forgot-password' ||
//     url.pathname === '/reset-success' ||
//     url.pathname === '/reset-fail'
//   ) {
//     const container = document.getElementById('root')
//     if (container) {
//       const queryClient = new QueryClient()
//       createRoot(container).render(
//         <QueryClientProvider client={queryClient}>
//           <BrowserRouter basename={PUBLIC_URL}>
//             <Routes>
//               <Route path='/' element={<Landing />} />
//               <Route path='/login' element={<Login />} />
//               <Route path='/privacy' element={<>Privacy Statement here</>} />
//               <Route path='/signup' element={<Signup />} />
//               <Route path='/health' element={<Health />} />
//               <Route path='/please-verify' element={<VerifyEmail />} />
//               <Route path='/forgot-message' element={<ForgotPasswordMessage />} />
//               <Route path='/forgot-password' element={<ForgotPassword />} />
//               <Route path='/reset-password/:resetCode' element={<ResetPassword />} />
//               <Route path='/reset-success' element={<PasswordResetSuccess />} />
//               <Route path='/reset-fail' element={<PasswordResetFail />} />
//               <Route
//                 path='/verify-email/:verificationString'
//                 element={<EmailVerificationLanding />}
//               />
//             </Routes>
//           </BrowserRouter>
//         </QueryClientProvider>
//       )
//     }
//   } else {
//     const container = document.getElementById('root')
//     if (container) {
//       const queryClient = new QueryClient()
//       createRoot(container).render( <QueryClientProvider client={queryClient}><AppRoutes />     </QueryClientProvider>)
//     }
//   }
// }
//load()
// use-query-params adapeter for React Router 6

const queryClient = new QueryClient()
const {PUBLIC_URL} = process.env
const domNode = document.getElementById('root')

const root = ReactDOM.createRoot(domNode as HTMLElement)
root.render(
  <BrowserRouter basename={PUBLIC_URL}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </QueryClientProvider>
  </BrowserRouter>
)
// ReactDOM.render(
//   <BrowserRouter basename={PUBLIC_URL}>
//     <QueryClientProvider client={queryClient}>
//       <App />
//     </QueryClientProvider>
//   </BrowserRouter>,
//   document.getElementById('root')
// )
