import React from 'react'
import ResetPasswordForm from '../../components/forms/resetPasswordForm'
import LogoBar from '../../components/logoBar'
import FlexHeadFoot from '../../layouts/flexheadfoot'

export default function ResetPassword(): React.ReactElement {
  return (
    <FlexHeadFoot header={<LogoBar />}>
      <ResetPasswordForm />
    </FlexHeadFoot>
  )
}
