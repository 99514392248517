import React from 'react'
import LoginForm from '../../components/forms/loginForm'
import LogoBar from '../../components/logoBar'
import FlexHeadFoot from '../../layouts/flexheadfoot'
import {GoogleOAuthProvider} from '@react-oauth/google'

export default function Login(): React.ReactElement {
  return (
    <FlexHeadFoot header={<LogoBar />}>
      <GoogleOAuthProvider clientId='1861740967-8lkuk9pkl79se0editr8mldgqgcih1nr.apps.googleusercontent.com'>
        <LoginForm />
      </GoogleOAuthProvider>
    </FlexHeadFoot>
  )
}
