import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
`

interface PaneProps {
  weight: string
  color?: string
  bgColor?: string
}
const Pane = styled.div<PaneProps>`
  flex: ${(props) => props.weight};
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  padding: 1.5em;
`

type Props = {
  children: React.ReactNode[]
  leftFlex: string
  leftColor?: string
  leftBgColor?: string
  rightFlex?: string
  rightColor?: string
  rightBgColor?: string
}

export const SplitScreen = ({
  children,
  leftFlex = '1',
  leftColor = '#000000',
  leftBgColor = '#FFFFFF',
  rightFlex = '1',
  rightColor = '#000000',
  rightBgColor = '#FFFFFF',
}: Props) => {
  const [left, right] = children
  return (
    <Container>
      <Pane weight={leftFlex} color={leftColor} bgColor={leftBgColor}>
        {left}
      </Pane>
      <Pane weight={rightFlex} color={rightColor} bgColor={rightBgColor}>
        {right}
      </Pane>
    </Container>
  )
}
