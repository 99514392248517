import ContactMailIcon from '@mui/icons-material/ContactMail'
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice'
import Logout from '@mui/icons-material/Logout'
import PersonAdd from '@mui/icons-material/PersonAdd'
import Settings from '@mui/icons-material/Settings'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import {RouteButton} from '../components/routebutton'
import {emToAbsoluteUrl} from '../utils/extensionMethods'
import {useAuth} from 'auth/useAuth'
import {useAuthProvider} from 'auth/AuthProvider'

export default function LandingBar({hideIT}: {hideIT: boolean}) {
  const navigate = useNavigate()
  const {currentUser, logout} = useAuthProvider()
  const LoggedInNavigationBar = (): React.ReactElement => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    const handleLogout = () => {
      logout()
    }

    const handleNav = () => {
      handleClose()
      navigate('')
    }

    return (
      <React.Fragment>
        <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
          <MenuItem onClick={() => alert('goto messages')}>
            <Box display={'flex'} gap={1}>
              <LocalPostOfficeIcon />
              <span style={{color: 'inherit'}}>
                <Typography variant={'body1'}>Messages</Typography>
              </span>
            </Box>
          </MenuItem>
          <MenuItem onClick={() => alert('goto contacts')}>
            <Box display={'flex'} gap={1}>
              <ContactMailIcon />
              <span style={{color: 'inherit'}}>
                <Typography variant={'body1'}>Contacts</Typography>
              </span>
            </Box>
          </MenuItem>

          <Tooltip title='Account settings'>
            <IconButton
              onClick={handleClick}
              size='small'
              sx={{ml: 2}}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{width: 32, height: 32}}>M</Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{horizontal: 'right', vertical: 'top'}}
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
          <MenuItem onClick={handleClose}>
            <Avatar /> Profile
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonAdd fontSize='small' />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize='small' />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }

  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position='static' sx={{backgroundColor: '#232323'}}>
        <Container maxWidth='xl'>
          {hideIT ? (
            <></>
          ) : (
            <Toolbar disableGutters>
              <Box sx={{flexGrow: 1, display: {xs: 'flex'}}}>
                <div
                  style={{
                    display: 'flex',
                    maxWidth: '48px',
                    minWidth: '32px',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={emToAbsoluteUrl('../static/assets/logo.png')}
                    alt='Mu logo'
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
                <Typography
                  variant='h6'
                  noWrap
                  component='a'
                  href='/'
                  sx={{
                    p: '1em',
                    ml: '1em',
                    display: {xs: 'flex'},
                    fontFamily: 'Alatsi',
                    letterSpacing: '.5rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  MESSAGE UNIVERSE
                </Typography>
              </Box>
              <Box gap={4} justifyContent={'end'} display={'flex'}>
                {currentUser ? (
                  <LoggedInNavigationBar />
                ) : (
                  <>
                    <RouteButton btntext='Signup' route={'signup'} variant={'text'} />
                    <RouteButton btntext='Login' route={'login'} />
                  </>
                )}
              </Box>
            </Toolbar>
          )}
        </Container>
      </AppBar>
    </Box>
  )
}
