import Box from '@mui/material/Box'
import React from 'react'
import LandingBar from '../../layouts/LandingBar'
import FlexHeadFoot from '../../layouts/flexheadfoot'
import PaymentWrapper from './components/payment/PaymentIntentWapper'
import {useAuthProvider} from 'auth/AuthProvider'
import Typography from '@mui/material/Typography'

export default function Home(): React.ReactElement {
  const {currentUser} = useAuthProvider()
  const Home = (): React.ReactElement => {
    return (
      <Box>
        <Typography variant={'h6'}>Home</Typography>
        {JSON.stringify(currentUser)}
      </Box>
    )
  }
  const hideIT: boolean = process.env.REACT_APP_HIDE_IT === 'TRUE' ? true : false

  return (
    <>
      {hideIT ? (
        <Home />
      ) : (
        <FlexHeadFoot header={<LandingBar hideIT={hideIT} />}>
          <Home />
        </FlexHeadFoot>
      )}
    </>
  )
}
