import React from 'react'
import ForgotPasswordForm from '../../components/forms/forgotPasswordForm'
import LogoBar from '../../components/logoBar'
import FlexHeadFoot from '../../layouts/flexheadfoot'

export default function ForgotPassword(): React.ReactElement {
  return (
    <FlexHeadFoot header={<LogoBar />}>
      <ForgotPasswordForm />
    </FlexHeadFoot>
  )
}
