import axios from 'axios'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {EmailVerificationFail} from './emailVerificationFail'
import {EmailVerificationSuccess} from './emailVerificationSuccess'
import {apiEndpoint} from 'config/apiConfig'

export const EmailVerificationLanding = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)

  const {verificationString} = useParams()

  useEffect(() => {
    const loadVerification = async () => {
      try {
        const response = await axios.put(apiEndpoint('auth/verify-email'), {
          verificationString,
        })
        const {token} = response.data
        setIsSuccess(true)
        setIsLoading(false)
      } catch (e) {
        setIsSuccess(false)
        setIsLoading(false)
      }
    }
    loadVerification()
  }, [verificationString])

  if (isLoading) return <p>Loading</p>
  if (!isSuccess) return <EmailVerificationFail />
  return <EmailVerificationSuccess />
}
