import moment from 'moment'
//for now, figuring that ave gb cost of storage is .023, that increases at 2.5% with inflation over year
//then the increase of maintenance is double of inflation * year divided in half. Divided the year in half
//to estimate an increase of maintenance going up but also storage costs decreasing. Simplest mathematics to start
//with for now

const percentInflation = 1 + 2.5 / 100
const percentMuMaintExpansionGig = 0.056
const percentMuMaintExpansionMeg = 0.000056
export const CURRENTGIGCOST = 0.023
export const CURRENTMEGCOST = 0.000023

export const getInflationOverYerPerGigaByte = (
  year: number,
  currentGigCost: number,
  gigsToBuy: number
) => {
  let money = currentGigCost + percentMuMaintExpansionGig * (year / 2) //1 is .5, 2 is 1, 3 is 1.5, 2 is 2
  let num = 0
  let total = 0
  const percent = percentInflation
  for (let y = 1; y <= Math.ceil(year); y++) {
    num = num + 1
    money = money * percent
    //  increment total
    total += money
  }
  // take the money originally set from currentGigCost and multiple number of years inflation
  const retval = money * gigsToBuy
  return retval
}

export const getInflationOverYerPerMegaByte = (
  year: number,
  currentMegCost: number,
  gigsToBuy: number
) => {
  let money = currentMegCost + percentMuMaintExpansionMeg * (year / 2)
  let num = 0
  let total = 0
  const percent = percentInflation
  for (let y = 1; y <= Math.ceil(year); y++) {
    num = num + 1
    money = money * percent
    //  increment total
    total += money
  }
  // take the money originally set from currentGigCost and multiple number of years inflation
  const retval = money * gigsToBuy
  return retval
}

export const ShowInflationCostsPerGigabyte = () => {
  const today = new Date()
  const currentMoment = moment()
  const endMoment = moment().add(100, 'year')

  //1 Gigabyte
  const storageAmount = 1
  while (currentMoment.isBefore(endMoment, 'year')) {
    currentMoment.add(1, 'year')
    const year = currentMoment.format('YYYY') as unknown as number
    const amount = getInflationOverYerPerGigaByte(
      year - today.getFullYear(),
      CURRENTGIGCOST,
      storageAmount
    )
    console.log(`Amount for ${year} is: ${amount}`)
  }
}

export const ShowInflationCostsPerMegabyte = () => {
  const today = new Date()
  const currentMoment = moment()
  const endMoment = moment().add(100, 'year')

  //1 Gigabyte
  const storageAmount = 1000
  while (currentMoment.isBefore(endMoment, 'year')) {
    currentMoment.add(1, 'year')
    const year = currentMoment.format('YYYY') as unknown as number
    const amount = getInflationOverYerPerMegaByte(
      year - today.getFullYear(),
      CURRENTMEGCOST,
      storageAmount
    )
    console.log(`Amount for ${year} is: ${amount}`)
  }
}
//if MB is minimum unit, then all boils down to min
//allow select increases of 500MB / gives 2 choices per year up to gig byte
//for 100 years times 2 = 200 products for MB choices, plus special plans,
//then 1GB/2GB/3GB/5GB/8GB/12GB/20GB/50GB/100GB/250GB/500GB/750GB/1TB/2TB 14 choices * 100 = 1400 products plus special plans
//should have around 1620 poducts (10 are bundles)

//2TB plan at 2122 PLAN_2000000MB_Y2122
//1GB plan at 2121 PLAN_1000MB_Y2121

//if you make product 122MB_year2199, id:12312321, price:33.32
//then how to look up same product?

//now generate all the plans and plan names and prices for a pricing catalog
//finally, setup logic to estimate which plan to use when allocating user's storage limits and allocation
//so will need a dashboard to demonstrate the storage available and storage used
//what about range coverage? All extensions include the range from now until the end date.

export const PriceCatalog = [
  {Year: 2025, Storage: 100, Amount: 99},
  {Year: 2025, Storage: 250, Amount: 99},
  {Year: 2025, Storage: 500, Amount: 99},
  {Year: 2025, Storage: 750, Amount: 99},
  {Year: 2025, Storage: 1000, Amount: 99},
  {Year: 2025, Storage: 2000, Amount: 99},
  {Year: 2025, Storage: 3000, Amount: 99},
  {Year: 2025, Storage: 5000, Amount: 99},
  {Year: 2025, Storage: 8000, Amount: 99},
  {Year: 2025, Storage: 12000, Amount: 99},
  {Year: 2025, Storage: 20000, Amount: 199},
  {Year: 2025, Storage: 50000, Amount: 399},
  {Year: 2025, Storage: 100000, Amount: 599},
  {Year: 2025, Storage: 250000, Amount: 1399},
  {Year: 2025, Storage: 500000, Amount: 2699},
  {Year: 2025, Storage: 750000, Amount: 3999},
  {Year: 2025, Storage: 1000000, Amount: 5299},
  {Year: 2025, Storage: 2000000, Amount: 10599},
  {Year: 2026, Storage: 100, Amount: 99},
  {Year: 2026, Storage: 250, Amount: 99},
  {Year: 2026, Storage: 500, Amount: 99},
  {Year: 2026, Storage: 750, Amount: 99},
  {Year: 2026, Storage: 1000, Amount: 99},
  {Year: 2026, Storage: 2000, Amount: 99},
  {Year: 2026, Storage: 3000, Amount: 99},
  {Year: 2026, Storage: 5000, Amount: 99},
  {Year: 2026, Storage: 8000, Amount: 99},
  {Year: 2026, Storage: 12000, Amount: 99},
  {Year: 2026, Storage: 20000, Amount: 299},
  {Year: 2026, Storage: 50000, Amount: 499},
  {Year: 2026, Storage: 100000, Amount: 899},
  {Year: 2026, Storage: 250000, Amount: 2199},
  {Year: 2026, Storage: 500000, Amount: 4199},
  {Year: 2026, Storage: 750000, Amount: 6299},
  {Year: 2026, Storage: 1000000, Amount: 8399},
  {Year: 2026, Storage: 2000000, Amount: 16699},
  {Year: 2027, Storage: 100, Amount: 99},
  {Year: 2027, Storage: 250, Amount: 99},
  {Year: 2027, Storage: 500, Amount: 99},
  {Year: 2027, Storage: 750, Amount: 99},
  {Year: 2027, Storage: 1000, Amount: 99},
  {Year: 2027, Storage: 2000, Amount: 99},
  {Year: 2027, Storage: 3000, Amount: 99},
  {Year: 2027, Storage: 5000, Amount: 99},
  {Year: 2027, Storage: 8000, Amount: 99},
  {Year: 2027, Storage: 12000, Amount: 199},
  {Year: 2027, Storage: 20000, Amount: 299},
  {Year: 2027, Storage: 50000, Amount: 699},
  {Year: 2027, Storage: 100000, Amount: 1299},
  {Year: 2027, Storage: 250000, Amount: 2999},
  {Year: 2027, Storage: 500000, Amount: 5899},
  {Year: 2027, Storage: 750000, Amount: 8699},
  {Year: 2027, Storage: 1000000, Amount: 11599},
  {Year: 2027, Storage: 2000000, Amount: 23099},
  {Year: 2028, Storage: 100, Amount: 99},
  {Year: 2028, Storage: 250, Amount: 99},
  {Year: 2028, Storage: 500, Amount: 99},
  {Year: 2028, Storage: 750, Amount: 99},
  {Year: 2028, Storage: 1000, Amount: 99},
  {Year: 2028, Storage: 2000, Amount: 99},
  {Year: 2028, Storage: 3000, Amount: 99},
  {Year: 2028, Storage: 5000, Amount: 99},
  {Year: 2028, Storage: 8000, Amount: 199},
  {Year: 2028, Storage: 12000, Amount: 299},
  {Year: 2028, Storage: 20000, Amount: 399},
  {Year: 2028, Storage: 50000, Amount: 799},
  {Year: 2028, Storage: 100000, Amount: 1599},
  {Year: 2028, Storage: 250000, Amount: 3799},
  {Year: 2028, Storage: 500000, Amount: 7599},
  {Year: 2028, Storage: 750000, Amount: 11299},
  {Year: 2028, Storage: 1000000, Amount: 14999},
  {Year: 2028, Storage: 2000000, Amount: 29899},
  {Year: 2029, Storage: 100, Amount: 99},
  {Year: 2029, Storage: 250, Amount: 99},
  {Year: 2029, Storage: 500, Amount: 99},
  {Year: 2029, Storage: 750, Amount: 99},
  {Year: 2029, Storage: 1000, Amount: 99},
  {Year: 2029, Storage: 2000, Amount: 99},
  {Year: 2029, Storage: 3000, Amount: 99},
  {Year: 2029, Storage: 5000, Amount: 99},
  {Year: 2029, Storage: 8000, Amount: 199},
  {Year: 2029, Storage: 12000, Amount: 299},
  {Year: 2029, Storage: 20000, Amount: 499},
  {Year: 2029, Storage: 50000, Amount: 999},
  {Year: 2029, Storage: 100000, Amount: 1899},
  {Year: 2029, Storage: 250000, Amount: 4699},
  {Year: 2029, Storage: 500000, Amount: 9299},
  {Year: 2029, Storage: 750000, Amount: 13899},
  {Year: 2029, Storage: 1000000, Amount: 18499},
  {Year: 2029, Storage: 2000000, Amount: 36999},
  {Year: 2030, Storage: 100, Amount: 99},
  {Year: 2030, Storage: 250, Amount: 99},
  {Year: 2030, Storage: 500, Amount: 99},
  {Year: 2030, Storage: 750, Amount: 99},
  {Year: 2030, Storage: 1000, Amount: 99},
  {Year: 2030, Storage: 2000, Amount: 99},
  {Year: 2030, Storage: 3000, Amount: 99},
  {Year: 2030, Storage: 5000, Amount: 199},
  {Year: 2030, Storage: 8000, Amount: 299},
  {Year: 2030, Storage: 12000, Amount: 399},
  {Year: 2030, Storage: 20000, Amount: 499},
  {Year: 2030, Storage: 50000, Amount: 1199},
  {Year: 2030, Storage: 100000, Amount: 2299},
  {Year: 2030, Storage: 250000, Amount: 5599},
  {Year: 2030, Storage: 500000, Amount: 11199},
  {Year: 2030, Storage: 750000, Amount: 16699},
  {Year: 2030, Storage: 1000000, Amount: 22299},
  {Year: 2030, Storage: 2000000, Amount: 44399},
  {Year: 2031, Storage: 100, Amount: 99},
  {Year: 2031, Storage: 250, Amount: 99},
  {Year: 2031, Storage: 500, Amount: 99},
  {Year: 2031, Storage: 750, Amount: 99},
  {Year: 2031, Storage: 1000, Amount: 99},
  {Year: 2031, Storage: 2000, Amount: 99},
  {Year: 2031, Storage: 3000, Amount: 99},
  {Year: 2031, Storage: 5000, Amount: 199},
  {Year: 2031, Storage: 8000, Amount: 299},
  {Year: 2031, Storage: 12000, Amount: 399},
  {Year: 2031, Storage: 20000, Amount: 599},
  {Year: 2031, Storage: 50000, Amount: 1399},
  {Year: 2031, Storage: 100000, Amount: 2699},
  {Year: 2031, Storage: 250000, Amount: 6599},
  {Year: 2031, Storage: 500000, Amount: 13099},
  {Year: 2031, Storage: 750000, Amount: 19599},
  {Year: 2031, Storage: 1000000, Amount: 26099},
  {Year: 2031, Storage: 2000000, Amount: 52199},
  {Year: 2032, Storage: 100, Amount: 99},
  {Year: 2032, Storage: 250, Amount: 99},
  {Year: 2032, Storage: 500, Amount: 99},
  {Year: 2032, Storage: 750, Amount: 99},
  {Year: 2032, Storage: 1000, Amount: 99},
  {Year: 2032, Storage: 2000, Amount: 99},
  {Year: 2032, Storage: 3000, Amount: 99},
  {Year: 2032, Storage: 5000, Amount: 299},
  {Year: 2032, Storage: 8000, Amount: 299},
  {Year: 2032, Storage: 12000, Amount: 499},
  {Year: 2032, Storage: 20000, Amount: 699},
  {Year: 2032, Storage: 50000, Amount: 1599},
  {Year: 2032, Storage: 100000, Amount: 3099},
  {Year: 2032, Storage: 250000, Amount: 7599},
  {Year: 2032, Storage: 500000, Amount: 15099},
  {Year: 2032, Storage: 750000, Amount: 22699},
  {Year: 2032, Storage: 1000000, Amount: 30199},
  {Year: 2032, Storage: 2000000, Amount: 60299},
  {Year: 2033, Storage: 100, Amount: 99},
  {Year: 2033, Storage: 250, Amount: 99},
  {Year: 2033, Storage: 500, Amount: 99},
  {Year: 2033, Storage: 750, Amount: 99},
  {Year: 2033, Storage: 1000, Amount: 99},
  {Year: 2033, Storage: 2000, Amount: 99},
  {Year: 2033, Storage: 3000, Amount: 199},
  {Year: 2033, Storage: 5000, Amount: 299},
  {Year: 2033, Storage: 8000, Amount: 399},
  {Year: 2033, Storage: 12000, Amount: 499},
  {Year: 2033, Storage: 20000, Amount: 799},
  {Year: 2033, Storage: 50000, Amount: 1799},
  {Year: 2033, Storage: 100000, Amount: 3499},
  {Year: 2033, Storage: 250000, Amount: 8699},
  {Year: 2033, Storage: 500000, Amount: 17299},
  {Year: 2033, Storage: 750000, Amount: 25899},
  {Year: 2033, Storage: 1000000, Amount: 34399},
  {Year: 2033, Storage: 2000000, Amount: 68799},
  {Year: 2034, Storage: 100, Amount: 99},
  {Year: 2034, Storage: 250, Amount: 99},
  {Year: 2034, Storage: 500, Amount: 99},
  {Year: 2034, Storage: 750, Amount: 99},
  {Year: 2034, Storage: 1000, Amount: 99},
  {Year: 2034, Storage: 2000, Amount: 99},
  {Year: 2034, Storage: 3000, Amount: 199},
  {Year: 2034, Storage: 5000, Amount: 299},
  {Year: 2034, Storage: 8000, Amount: 399},
  {Year: 2034, Storage: 12000, Amount: 599},
  {Year: 2034, Storage: 20000, Amount: 899},
  {Year: 2034, Storage: 50000, Amount: 1999},
  {Year: 2034, Storage: 100000, Amount: 3999},
  {Year: 2034, Storage: 250000, Amount: 9799},
  {Year: 2034, Storage: 500000, Amount: 19499},
  {Year: 2034, Storage: 750000, Amount: 29199},
  {Year: 2034, Storage: 1000000, Amount: 38899},
  {Year: 2034, Storage: 2000000, Amount: 77699},
  {Year: 2035, Storage: 100, Amount: 99},
  {Year: 2035, Storage: 250, Amount: 99},
  {Year: 2035, Storage: 500, Amount: 99},
  {Year: 2035, Storage: 750, Amount: 99},
  {Year: 2035, Storage: 1000, Amount: 99},
  {Year: 2035, Storage: 2000, Amount: 99},
  {Year: 2035, Storage: 3000, Amount: 199},
  {Year: 2035, Storage: 5000, Amount: 299},
  {Year: 2035, Storage: 8000, Amount: 399},
  {Year: 2035, Storage: 12000, Amount: 599},
  {Year: 2035, Storage: 20000, Amount: 999},
  {Year: 2035, Storage: 50000, Amount: 2299},
  {Year: 2035, Storage: 100000, Amount: 4399},
  {Year: 2035, Storage: 250000, Amount: 10999},
  {Year: 2035, Storage: 500000, Amount: 21799},
  {Year: 2035, Storage: 750000, Amount: 32699},
  {Year: 2035, Storage: 1000000, Amount: 43499},
  {Year: 2035, Storage: 2000000, Amount: 86999},
  {Year: 2036, Storage: 100, Amount: 99},
  {Year: 2036, Storage: 250, Amount: 99},
  {Year: 2036, Storage: 500, Amount: 99},
  {Year: 2036, Storage: 750, Amount: 99},
  {Year: 2036, Storage: 1000, Amount: 99},
  {Year: 2036, Storage: 2000, Amount: 99},
  {Year: 2036, Storage: 3000, Amount: 199},
  {Year: 2036, Storage: 5000, Amount: 299},
  {Year: 2036, Storage: 8000, Amount: 499},
  {Year: 2036, Storage: 12000, Amount: 699},
  {Year: 2036, Storage: 20000, Amount: 1099},
  {Year: 2036, Storage: 50000, Amount: 2499},
  {Year: 2036, Storage: 100000, Amount: 4899},
  {Year: 2036, Storage: 250000, Amount: 12199},
  {Year: 2036, Storage: 500000, Amount: 24199},
  {Year: 2036, Storage: 750000, Amount: 36299},
  {Year: 2036, Storage: 1000000, Amount: 48399},
  {Year: 2036, Storage: 2000000, Amount: 96699},
  {Year: 2037, Storage: 100, Amount: 99},
  {Year: 2037, Storage: 250, Amount: 99},
  {Year: 2037, Storage: 500, Amount: 99},
  {Year: 2037, Storage: 750, Amount: 99},
  {Year: 2037, Storage: 1000, Amount: 99},
  {Year: 2037, Storage: 2000, Amount: 199},
  {Year: 2037, Storage: 3000, Amount: 299},
  {Year: 2037, Storage: 5000, Amount: 399},
  {Year: 2037, Storage: 8000, Amount: 499},
  {Year: 2037, Storage: 12000, Amount: 699},
  {Year: 2037, Storage: 20000, Amount: 1199},
  {Year: 2037, Storage: 50000, Amount: 2799},
  {Year: 2037, Storage: 100000, Amount: 5399},
  {Year: 2037, Storage: 250000, Amount: 13399},
  {Year: 2037, Storage: 500000, Amount: 26799},
  {Year: 2037, Storage: 750000, Amount: 40099},
  {Year: 2037, Storage: 1000000, Amount: 53399},
  {Year: 2037, Storage: 2000000, Amount: 106799},
  {Year: 2038, Storage: 100, Amount: 99},
  {Year: 2038, Storage: 250, Amount: 99},
  {Year: 2038, Storage: 500, Amount: 99},
  {Year: 2038, Storage: 750, Amount: 99},
  {Year: 2038, Storage: 1000, Amount: 99},
  {Year: 2038, Storage: 2000, Amount: 199},
  {Year: 2038, Storage: 3000, Amount: 299},
  {Year: 2038, Storage: 5000, Amount: 399},
  {Year: 2038, Storage: 8000, Amount: 599},
  {Year: 2038, Storage: 12000, Amount: 799},
  {Year: 2038, Storage: 20000, Amount: 1299},
  {Year: 2038, Storage: 50000, Amount: 2999},
  {Year: 2038, Storage: 100000, Amount: 5999},
  {Year: 2038, Storage: 250000, Amount: 14799},
  {Year: 2038, Storage: 500000, Amount: 29399},
  {Year: 2038, Storage: 750000, Amount: 44099},
  {Year: 2038, Storage: 1000000, Amount: 58699},
  {Year: 2038, Storage: 2000000, Amount: 117399},
  {Year: 2039, Storage: 100, Amount: 99},
  {Year: 2039, Storage: 250, Amount: 99},
  {Year: 2039, Storage: 500, Amount: 99},
  {Year: 2039, Storage: 750, Amount: 99},
  {Year: 2039, Storage: 1000, Amount: 99},
  {Year: 2039, Storage: 2000, Amount: 199},
  {Year: 2039, Storage: 3000, Amount: 299},
  {Year: 2039, Storage: 5000, Amount: 399},
  {Year: 2039, Storage: 8000, Amount: 599},
  {Year: 2039, Storage: 12000, Amount: 899},
  {Year: 2039, Storage: 20000, Amount: 1399},
  {Year: 2039, Storage: 50000, Amount: 3299},
  {Year: 2039, Storage: 100000, Amount: 6499},
  {Year: 2039, Storage: 250000, Amount: 16099},
  {Year: 2039, Storage: 500000, Amount: 32199},
  {Year: 2039, Storage: 750000, Amount: 48199},
  {Year: 2039, Storage: 1000000, Amount: 64299},
  {Year: 2039, Storage: 2000000, Amount: 128399},
  {Year: 2040, Storage: 100, Amount: 99},
  {Year: 2040, Storage: 250, Amount: 99},
  {Year: 2040, Storage: 500, Amount: 99},
  {Year: 2040, Storage: 750, Amount: 99},
  {Year: 2040, Storage: 1000, Amount: 99},
  {Year: 2040, Storage: 2000, Amount: 199},
  {Year: 2040, Storage: 3000, Amount: 299},
  {Year: 2040, Storage: 5000, Amount: 399},
  {Year: 2040, Storage: 8000, Amount: 699},
  {Year: 2040, Storage: 12000, Amount: 899},
  {Year: 2040, Storage: 20000, Amount: 1499},
  {Year: 2040, Storage: 50000, Amount: 3599},
  {Year: 2040, Storage: 100000, Amount: 7099},
  {Year: 2040, Storage: 250000, Amount: 17599},
  {Year: 2040, Storage: 500000, Amount: 35099},
  {Year: 2040, Storage: 750000, Amount: 52499},
  {Year: 2040, Storage: 1000000, Amount: 69999},
  {Year: 2040, Storage: 2000000, Amount: 139899},
  {Year: 2041, Storage: 100, Amount: 99},
  {Year: 2041, Storage: 250, Amount: 99},
  {Year: 2041, Storage: 500, Amount: 99},
  {Year: 2041, Storage: 750, Amount: 99},
  {Year: 2041, Storage: 1000, Amount: 99},
  {Year: 2041, Storage: 2000, Amount: 299},
  {Year: 2041, Storage: 3000, Amount: 299},
  {Year: 2041, Storage: 5000, Amount: 499},
  {Year: 2041, Storage: 8000, Amount: 699},
  {Year: 2041, Storage: 12000, Amount: 999},
  {Year: 2041, Storage: 20000, Amount: 1599},
  {Year: 2041, Storage: 50000, Amount: 3899},
  {Year: 2041, Storage: 100000, Amount: 7699},
  {Year: 2041, Storage: 250000, Amount: 19099},
  {Year: 2041, Storage: 500000, Amount: 38099},
  {Year: 2041, Storage: 750000, Amount: 56999},
  {Year: 2041, Storage: 1000000, Amount: 75999},
  {Year: 2041, Storage: 2000000, Amount: 151999},
  {Year: 2042, Storage: 100, Amount: 99},
  {Year: 2042, Storage: 250, Amount: 99},
  {Year: 2042, Storage: 500, Amount: 99},
  {Year: 2042, Storage: 750, Amount: 99},
  {Year: 2042, Storage: 1000, Amount: 99},
  {Year: 2042, Storage: 2000, Amount: 299},
  {Year: 2042, Storage: 3000, Amount: 299},
  {Year: 2042, Storage: 5000, Amount: 499},
  {Year: 2042, Storage: 8000, Amount: 799},
  {Year: 2042, Storage: 12000, Amount: 1099},
  {Year: 2042, Storage: 20000, Amount: 1699},
  {Year: 2042, Storage: 50000, Amount: 4199},
  {Year: 2042, Storage: 100000, Amount: 8299},
  {Year: 2042, Storage: 250000, Amount: 20599},
  {Year: 2042, Storage: 500000, Amount: 41199},
  {Year: 2042, Storage: 750000, Amount: 61699},
  {Year: 2042, Storage: 1000000, Amount: 82299},
  {Year: 2042, Storage: 2000000, Amount: 164499},
  {Year: 2043, Storage: 100, Amount: 99},
  {Year: 2043, Storage: 250, Amount: 99},
  {Year: 2043, Storage: 500, Amount: 99},
  {Year: 2043, Storage: 750, Amount: 99},
  {Year: 2043, Storage: 1000, Amount: 99},
  {Year: 2043, Storage: 2000, Amount: 299},
  {Year: 2043, Storage: 3000, Amount: 399},
  {Year: 2043, Storage: 5000, Amount: 499},
  {Year: 2043, Storage: 8000, Amount: 799},
  {Year: 2043, Storage: 12000, Amount: 1199},
  {Year: 2043, Storage: 20000, Amount: 1899},
  {Year: 2043, Storage: 50000, Amount: 4499},
  {Year: 2043, Storage: 100000, Amount: 8999},
  {Year: 2043, Storage: 250000, Amount: 22299},
  {Year: 2043, Storage: 500000, Amount: 44499},
  {Year: 2043, Storage: 750000, Amount: 66599},
  {Year: 2043, Storage: 1000000, Amount: 88799},
  {Year: 2043, Storage: 2000000, Amount: 177599},
  {Year: 2044, Storage: 100, Amount: 99},
  {Year: 2044, Storage: 250, Amount: 99},
  {Year: 2044, Storage: 500, Amount: 99},
  {Year: 2044, Storage: 750, Amount: 99},
  {Year: 2044, Storage: 1000, Amount: 99},
  {Year: 2044, Storage: 2000, Amount: 299},
  {Year: 2044, Storage: 3000, Amount: 399},
  {Year: 2044, Storage: 5000, Amount: 599},
  {Year: 2044, Storage: 8000, Amount: 899},
  {Year: 2044, Storage: 12000, Amount: 1199},
  {Year: 2044, Storage: 20000, Amount: 1999},
  {Year: 2044, Storage: 50000, Amount: 4899},
  {Year: 2044, Storage: 100000, Amount: 9699},
  {Year: 2044, Storage: 250000, Amount: 23999},
  {Year: 2044, Storage: 500000, Amount: 47899},
  {Year: 2044, Storage: 750000, Amount: 71699},
  {Year: 2044, Storage: 1000000, Amount: 95599},
  {Year: 2044, Storage: 2000000, Amount: 191199},
  {Year: 2045, Storage: 100, Amount: 99},
  {Year: 2045, Storage: 250, Amount: 99},
  {Year: 2045, Storage: 500, Amount: 99},
  {Year: 2045, Storage: 750, Amount: 99},
  {Year: 2045, Storage: 1000, Amount: 199},
  {Year: 2045, Storage: 2000, Amount: 299},
  {Year: 2045, Storage: 3000, Amount: 399},
  {Year: 2045, Storage: 5000, Amount: 599},
  {Year: 2045, Storage: 8000, Amount: 899},
  {Year: 2045, Storage: 12000, Amount: 1299},
  {Year: 2045, Storage: 20000, Amount: 2199},
  {Year: 2045, Storage: 50000, Amount: 5199},
  {Year: 2045, Storage: 100000, Amount: 10399},
  {Year: 2045, Storage: 250000, Amount: 25799},
  {Year: 2045, Storage: 500000, Amount: 51399},
  {Year: 2045, Storage: 750000, Amount: 77099},
  {Year: 2045, Storage: 1000000, Amount: 102699},
  {Year: 2045, Storage: 2000000, Amount: 205299},
  {Year: 2046, Storage: 100, Amount: 99},
  {Year: 2046, Storage: 250, Amount: 99},
  {Year: 2046, Storage: 500, Amount: 99},
  {Year: 2046, Storage: 750, Amount: 99},
  {Year: 2046, Storage: 1000, Amount: 199},
  {Year: 2046, Storage: 2000, Amount: 299},
  {Year: 2046, Storage: 3000, Amount: 399},
  {Year: 2046, Storage: 5000, Amount: 699},
  {Year: 2046, Storage: 8000, Amount: 999},
  {Year: 2046, Storage: 12000, Amount: 1399},
  {Year: 2046, Storage: 20000, Amount: 2299},
  {Year: 2046, Storage: 50000, Amount: 5599},
  {Year: 2046, Storage: 100000, Amount: 11099},
  {Year: 2046, Storage: 250000, Amount: 27599},
  {Year: 2046, Storage: 500000, Amount: 55099},
  {Year: 2046, Storage: 750000, Amount: 82599},
  {Year: 2046, Storage: 1000000, Amount: 110099},
  {Year: 2046, Storage: 2000000, Amount: 220099},
  {Year: 2047, Storage: 100, Amount: 99},
  {Year: 2047, Storage: 250, Amount: 99},
  {Year: 2047, Storage: 500, Amount: 99},
  {Year: 2047, Storage: 750, Amount: 99},
  {Year: 2047, Storage: 1000, Amount: 199},
  {Year: 2047, Storage: 2000, Amount: 299},
  {Year: 2047, Storage: 3000, Amount: 499},
  {Year: 2047, Storage: 5000, Amount: 699},
  {Year: 2047, Storage: 8000, Amount: 999},
  {Year: 2047, Storage: 12000, Amount: 1499},
  {Year: 2047, Storage: 20000, Amount: 2499},
  {Year: 2047, Storage: 50000, Amount: 5999},
  {Year: 2047, Storage: 100000, Amount: 11899},
  {Year: 2047, Storage: 250000, Amount: 29499},
  {Year: 2047, Storage: 500000, Amount: 58899},
  {Year: 2047, Storage: 750000, Amount: 88399},
  {Year: 2047, Storage: 1000000, Amount: 117799},
  {Year: 2047, Storage: 2000000, Amount: 235499},
  {Year: 2048, Storage: 100, Amount: 99},
  {Year: 2048, Storage: 250, Amount: 99},
  {Year: 2048, Storage: 500, Amount: 99},
  {Year: 2048, Storage: 750, Amount: 99},
  {Year: 2048, Storage: 1000, Amount: 199},
  {Year: 2048, Storage: 2000, Amount: 399},
  {Year: 2048, Storage: 3000, Amount: 499},
  {Year: 2048, Storage: 5000, Amount: 699},
  {Year: 2048, Storage: 8000, Amount: 1099},
  {Year: 2048, Storage: 12000, Amount: 1599},
  {Year: 2048, Storage: 20000, Amount: 2599},
  {Year: 2048, Storage: 50000, Amount: 6399},
  {Year: 2048, Storage: 100000, Amount: 12699},
  {Year: 2048, Storage: 250000, Amount: 31499},
  {Year: 2048, Storage: 500000, Amount: 62999},
  {Year: 2048, Storage: 750000, Amount: 94399},
  {Year: 2048, Storage: 1000000, Amount: 125799},
  {Year: 2048, Storage: 2000000, Amount: 251499},
  {Year: 2049, Storage: 100, Amount: 99},
  {Year: 2049, Storage: 250, Amount: 99},
  {Year: 2049, Storage: 500, Amount: 99},
  {Year: 2049, Storage: 750, Amount: 199},
  {Year: 2049, Storage: 1000, Amount: 199},
  {Year: 2049, Storage: 2000, Amount: 399},
  {Year: 2049, Storage: 3000, Amount: 499},
  {Year: 2049, Storage: 5000, Amount: 799},
  {Year: 2049, Storage: 8000, Amount: 1199},
  {Year: 2049, Storage: 12000, Amount: 1699},
  {Year: 2049, Storage: 20000, Amount: 2799},
  {Year: 2049, Storage: 50000, Amount: 6799},
  {Year: 2049, Storage: 100000, Amount: 13499},
  {Year: 2049, Storage: 250000, Amount: 33599},
  {Year: 2049, Storage: 500000, Amount: 67099},
  {Year: 2049, Storage: 750000, Amount: 100599},
  {Year: 2049, Storage: 1000000, Amount: 134099},
  {Year: 2049, Storage: 2000000, Amount: 268199},
  {Year: 2050, Storage: 100, Amount: 99},
  {Year: 2050, Storage: 250, Amount: 99},
  {Year: 2050, Storage: 500, Amount: 99},
  {Year: 2050, Storage: 750, Amount: 199},
  {Year: 2050, Storage: 1000, Amount: 199},
  {Year: 2050, Storage: 2000, Amount: 399},
  {Year: 2050, Storage: 3000, Amount: 499},
  {Year: 2050, Storage: 5000, Amount: 799},
  {Year: 2050, Storage: 8000, Amount: 1199},
  {Year: 2050, Storage: 12000, Amount: 1799},
  {Year: 2050, Storage: 20000, Amount: 2999},
  {Year: 2050, Storage: 50000, Amount: 7199},
  {Year: 2050, Storage: 100000, Amount: 14399},
  {Year: 2050, Storage: 250000, Amount: 35799},
  {Year: 2050, Storage: 500000, Amount: 71499},
  {Year: 2050, Storage: 750000, Amount: 107099},
  {Year: 2050, Storage: 1000000, Amount: 142799},
  {Year: 2050, Storage: 2000000, Amount: 285499},
  {Year: 2051, Storage: 100, Amount: 99},
  {Year: 2051, Storage: 250, Amount: 99},
  {Year: 2051, Storage: 500, Amount: 99},
  {Year: 2051, Storage: 750, Amount: 199},
  {Year: 2051, Storage: 1000, Amount: 299},
  {Year: 2051, Storage: 2000, Amount: 399},
  {Year: 2051, Storage: 3000, Amount: 599},
  {Year: 2051, Storage: 5000, Amount: 899},
  {Year: 2051, Storage: 8000, Amount: 1299},
  {Year: 2051, Storage: 12000, Amount: 1899},
  {Year: 2051, Storage: 20000, Amount: 3099},
  {Year: 2051, Storage: 50000, Amount: 7699},
  {Year: 2051, Storage: 100000, Amount: 15299},
  {Year: 2051, Storage: 250000, Amount: 37999},
  {Year: 2051, Storage: 500000, Amount: 75999},
  {Year: 2051, Storage: 750000, Amount: 113899},
  {Year: 2051, Storage: 1000000, Amount: 151799},
  {Year: 2051, Storage: 2000000, Amount: 303599},
  {Year: 2052, Storage: 100, Amount: 99},
  {Year: 2052, Storage: 250, Amount: 99},
  {Year: 2052, Storage: 500, Amount: 99},
  {Year: 2052, Storage: 750, Amount: 199},
  {Year: 2052, Storage: 1000, Amount: 299},
  {Year: 2052, Storage: 2000, Amount: 399},
  {Year: 2052, Storage: 3000, Amount: 599},
  {Year: 2052, Storage: 5000, Amount: 899},
  {Year: 2052, Storage: 8000, Amount: 1399},
  {Year: 2052, Storage: 12000, Amount: 1999},
  {Year: 2052, Storage: 20000, Amount: 3299},
  {Year: 2052, Storage: 50000, Amount: 8199},
  {Year: 2052, Storage: 100000, Amount: 16199},
  {Year: 2052, Storage: 250000, Amount: 40399},
  {Year: 2052, Storage: 500000, Amount: 80699},
  {Year: 2052, Storage: 750000, Amount: 120899},
  {Year: 2052, Storage: 1000000, Amount: 161199},
  {Year: 2052, Storage: 2000000, Amount: 322299},
  {Year: 2053, Storage: 100, Amount: 99},
  {Year: 2053, Storage: 250, Amount: 99},
  {Year: 2053, Storage: 500, Amount: 99},
  {Year: 2053, Storage: 750, Amount: 199},
  {Year: 2053, Storage: 1000, Amount: 299},
  {Year: 2053, Storage: 2000, Amount: 399},
  {Year: 2053, Storage: 3000, Amount: 599},
  {Year: 2053, Storage: 5000, Amount: 999},
  {Year: 2053, Storage: 8000, Amount: 1499},
  {Year: 2053, Storage: 12000, Amount: 2199},
  {Year: 2053, Storage: 20000, Amount: 3499},
  {Year: 2053, Storage: 50000, Amount: 8599},
  {Year: 2053, Storage: 100000, Amount: 17199},
  {Year: 2053, Storage: 250000, Amount: 42799},
  {Year: 2053, Storage: 500000, Amount: 85499},
  {Year: 2053, Storage: 750000, Amount: 128299},
  {Year: 2053, Storage: 1000000, Amount: 170999},
  {Year: 2053, Storage: 2000000, Amount: 341899},
  {Year: 2054, Storage: 100, Amount: 99},
  {Year: 2054, Storage: 250, Amount: 99},
  {Year: 2054, Storage: 500, Amount: 99},
  {Year: 2054, Storage: 750, Amount: 199},
  {Year: 2054, Storage: 1000, Amount: 299},
  {Year: 2054, Storage: 2000, Amount: 499},
  {Year: 2054, Storage: 3000, Amount: 599},
  {Year: 2054, Storage: 5000, Amount: 999},
  {Year: 2054, Storage: 8000, Amount: 1499},
  {Year: 2054, Storage: 12000, Amount: 2299},
  {Year: 2054, Storage: 20000, Amount: 3699},
  {Year: 2054, Storage: 50000, Amount: 9199},
  {Year: 2054, Storage: 100000, Amount: 18199},
  {Year: 2054, Storage: 250000, Amount: 45399},
  {Year: 2054, Storage: 500000, Amount: 90599},
  {Year: 2054, Storage: 750000, Amount: 135899},
  {Year: 2054, Storage: 1000000, Amount: 181099},
  {Year: 2054, Storage: 2000000, Amount: 362099},
  {Year: 2055, Storage: 100, Amount: 99},
  {Year: 2055, Storage: 250, Amount: 99},
  {Year: 2055, Storage: 500, Amount: 99},
  {Year: 2055, Storage: 750, Amount: 199},
  {Year: 2055, Storage: 1000, Amount: 299},
  {Year: 2055, Storage: 2000, Amount: 499},
  {Year: 2055, Storage: 3000, Amount: 699},
  {Year: 2055, Storage: 5000, Amount: 1099},
  {Year: 2055, Storage: 8000, Amount: 1599},
  {Year: 2055, Storage: 12000, Amount: 2399},
  {Year: 2055, Storage: 20000, Amount: 3899},
  {Year: 2055, Storage: 50000, Amount: 9699},
  {Year: 2055, Storage: 100000, Amount: 19299},
  {Year: 2055, Storage: 250000, Amount: 47999},
  {Year: 2055, Storage: 500000, Amount: 95899},
  {Year: 2055, Storage: 750000, Amount: 143799},
  {Year: 2055, Storage: 1000000, Amount: 191699},
  {Year: 2055, Storage: 2000000, Amount: 383199},
  {Year: 2056, Storage: 100, Amount: 99},
  {Year: 2056, Storage: 250, Amount: 99},
  {Year: 2056, Storage: 500, Amount: 199},
  {Year: 2056, Storage: 750, Amount: 299},
  {Year: 2056, Storage: 1000, Amount: 299},
  {Year: 2056, Storage: 2000, Amount: 499},
  {Year: 2056, Storage: 3000, Amount: 699},
  {Year: 2056, Storage: 5000, Amount: 1099},
  {Year: 2056, Storage: 8000, Amount: 1699},
  {Year: 2056, Storage: 12000, Amount: 2499},
  {Year: 2056, Storage: 20000, Amount: 4199},
  {Year: 2056, Storage: 50000, Amount: 10199},
  {Year: 2056, Storage: 100000, Amount: 20399},
  {Year: 2056, Storage: 250000, Amount: 50699},
  {Year: 2056, Storage: 500000, Amount: 101399},
  {Year: 2056, Storage: 750000, Amount: 151999},
  {Year: 2056, Storage: 1000000, Amount: 202599},
  {Year: 2056, Storage: 2000000, Amount: 405199},
  {Year: 2057, Storage: 100, Amount: 99},
  {Year: 2057, Storage: 250, Amount: 99},
  {Year: 2057, Storage: 500, Amount: 199},
  {Year: 2057, Storage: 750, Amount: 299},
  {Year: 2057, Storage: 1000, Amount: 299},
  {Year: 2057, Storage: 2000, Amount: 499},
  {Year: 2057, Storage: 3000, Amount: 699},
  {Year: 2057, Storage: 5000, Amount: 1199},
  {Year: 2057, Storage: 8000, Amount: 1799},
  {Year: 2057, Storage: 12000, Amount: 2699},
  {Year: 2057, Storage: 20000, Amount: 4399},
  {Year: 2057, Storage: 50000, Amount: 10799},
  {Year: 2057, Storage: 100000, Amount: 21499},
  {Year: 2057, Storage: 250000, Amount: 53599},
  {Year: 2057, Storage: 500000, Amount: 107099},
  {Year: 2057, Storage: 750000, Amount: 160499},
  {Year: 2057, Storage: 1000000, Amount: 213999},
  {Year: 2057, Storage: 2000000, Amount: 427899},
  {Year: 2058, Storage: 100, Amount: 99},
  {Year: 2058, Storage: 250, Amount: 99},
  {Year: 2058, Storage: 500, Amount: 199},
  {Year: 2058, Storage: 750, Amount: 299},
  {Year: 2058, Storage: 1000, Amount: 299},
  {Year: 2058, Storage: 2000, Amount: 599},
  {Year: 2058, Storage: 3000, Amount: 799},
  {Year: 2058, Storage: 5000, Amount: 1199},
  {Year: 2058, Storage: 8000, Amount: 1899},
  {Year: 2058, Storage: 12000, Amount: 2799},
  {Year: 2058, Storage: 20000, Amount: 4599},
  {Year: 2058, Storage: 50000, Amount: 11399},
  {Year: 2058, Storage: 100000, Amount: 22699},
  {Year: 2058, Storage: 250000, Amount: 56499},
  {Year: 2058, Storage: 500000, Amount: 112999},
  {Year: 2058, Storage: 750000, Amount: 169399},
  {Year: 2058, Storage: 1000000, Amount: 225799},
  {Year: 2058, Storage: 2000000, Amount: 451599},
  {Year: 2059, Storage: 100, Amount: 99},
  {Year: 2059, Storage: 250, Amount: 99},
  {Year: 2059, Storage: 500, Amount: 199},
  {Year: 2059, Storage: 750, Amount: 299},
  {Year: 2059, Storage: 1000, Amount: 299},
  {Year: 2059, Storage: 2000, Amount: 599},
  {Year: 2059, Storage: 3000, Amount: 799},
  {Year: 2059, Storage: 5000, Amount: 1299},
  {Year: 2059, Storage: 8000, Amount: 1999},
  {Year: 2059, Storage: 12000, Amount: 2999},
  {Year: 2059, Storage: 20000, Amount: 4899},
  {Year: 2059, Storage: 50000, Amount: 11999},
  {Year: 2059, Storage: 100000, Amount: 23899},
  {Year: 2059, Storage: 250000, Amount: 59599},
  {Year: 2059, Storage: 500000, Amount: 119099},
  {Year: 2059, Storage: 750000, Amount: 178599},
  {Year: 2059, Storage: 1000000, Amount: 238099},
  {Year: 2059, Storage: 2000000, Amount: 476199},
  {Year: 2060, Storage: 100, Amount: 99},
  {Year: 2060, Storage: 250, Amount: 99},
  {Year: 2060, Storage: 500, Amount: 199},
  {Year: 2060, Storage: 750, Amount: 299},
  {Year: 2060, Storage: 1000, Amount: 399},
  {Year: 2060, Storage: 2000, Amount: 599},
  {Year: 2060, Storage: 3000, Amount: 899},
  {Year: 2060, Storage: 5000, Amount: 1399},
  {Year: 2060, Storage: 8000, Amount: 2099},
  {Year: 2060, Storage: 12000, Amount: 3099},
  {Year: 2060, Storage: 20000, Amount: 5099},
  {Year: 2060, Storage: 50000, Amount: 12599},
  {Year: 2060, Storage: 100000, Amount: 25199},
  {Year: 2060, Storage: 250000, Amount: 62799},
  {Year: 2060, Storage: 500000, Amount: 125499},
  {Year: 2060, Storage: 750000, Amount: 188199},
  {Year: 2060, Storage: 1000000, Amount: 250899},
  {Year: 2060, Storage: 2000000, Amount: 501699},
  {Year: 2061, Storage: 100, Amount: 99},
  {Year: 2061, Storage: 250, Amount: 99},
  {Year: 2061, Storage: 500, Amount: 199},
  {Year: 2061, Storage: 750, Amount: 299},
  {Year: 2061, Storage: 1000, Amount: 399},
  {Year: 2061, Storage: 2000, Amount: 599},
  {Year: 2061, Storage: 3000, Amount: 899},
  {Year: 2061, Storage: 5000, Amount: 1399},
  {Year: 2061, Storage: 8000, Amount: 2199},
  {Year: 2061, Storage: 12000, Amount: 3299},
  {Year: 2061, Storage: 20000, Amount: 5399},
  {Year: 2061, Storage: 50000, Amount: 13299},
  {Year: 2061, Storage: 100000, Amount: 26499},
  {Year: 2061, Storage: 250000, Amount: 66099},
  {Year: 2061, Storage: 500000, Amount: 132099},
  {Year: 2061, Storage: 750000, Amount: 198099},
  {Year: 2061, Storage: 1000000, Amount: 264099},
  {Year: 2061, Storage: 2000000, Amount: 528199},
  {Year: 2062, Storage: 100, Amount: 99},
  {Year: 2062, Storage: 250, Amount: 99},
  {Year: 2062, Storage: 500, Amount: 199},
  {Year: 2062, Storage: 750, Amount: 299},
  {Year: 2062, Storage: 1000, Amount: 399},
  {Year: 2062, Storage: 2000, Amount: 699},
  {Year: 2062, Storage: 3000, Amount: 899},
  {Year: 2062, Storage: 5000, Amount: 1499},
  {Year: 2062, Storage: 8000, Amount: 2299},
  {Year: 2062, Storage: 12000, Amount: 3399},
  {Year: 2062, Storage: 20000, Amount: 5699},
  {Year: 2062, Storage: 50000, Amount: 13999},
  {Year: 2062, Storage: 100000, Amount: 27899},
  {Year: 2062, Storage: 250000, Amount: 69599},
  {Year: 2062, Storage: 500000, Amount: 138999},
  {Year: 2062, Storage: 750000, Amount: 208499},
  {Year: 2062, Storage: 1000000, Amount: 277899},
  {Year: 2062, Storage: 2000000, Amount: 555699},
  {Year: 2063, Storage: 100, Amount: 99},
  {Year: 2063, Storage: 250, Amount: 99},
  {Year: 2063, Storage: 500, Amount: 199},
  {Year: 2063, Storage: 750, Amount: 299},
  {Year: 2063, Storage: 1000, Amount: 399},
  {Year: 2063, Storage: 2000, Amount: 699},
  {Year: 2063, Storage: 3000, Amount: 999},
  {Year: 2063, Storage: 5000, Amount: 1599},
  {Year: 2063, Storage: 8000, Amount: 2399},
  {Year: 2063, Storage: 12000, Amount: 3599},
  {Year: 2063, Storage: 20000, Amount: 5899},
  {Year: 2063, Storage: 50000, Amount: 14699},
  {Year: 2063, Storage: 100000, Amount: 29299},
  {Year: 2063, Storage: 250000, Amount: 73099},
  {Year: 2063, Storage: 500000, Amount: 146099},
  {Year: 2063, Storage: 750000, Amount: 219199},
  {Year: 2063, Storage: 1000000, Amount: 292199},
  {Year: 2063, Storage: 2000000, Amount: 584299},
  {Year: 2064, Storage: 100, Amount: 99},
  {Year: 2064, Storage: 250, Amount: 99},
  {Year: 2064, Storage: 500, Amount: 299},
  {Year: 2064, Storage: 750, Amount: 299},
  {Year: 2064, Storage: 1000, Amount: 399},
  {Year: 2064, Storage: 2000, Amount: 699},
  {Year: 2064, Storage: 3000, Amount: 999},
  {Year: 2064, Storage: 5000, Amount: 1599},
  {Year: 2064, Storage: 8000, Amount: 2599},
  {Year: 2064, Storage: 12000, Amount: 3799},
  {Year: 2064, Storage: 20000, Amount: 6199},
  {Year: 2064, Storage: 50000, Amount: 15399},
  {Year: 2064, Storage: 100000, Amount: 30799},
  {Year: 2064, Storage: 250000, Amount: 76799},
  {Year: 2064, Storage: 500000, Amount: 153599},
  {Year: 2064, Storage: 750000, Amount: 230299},
  {Year: 2064, Storage: 1000000, Amount: 306999},
  {Year: 2064, Storage: 2000000, Amount: 613899},
  {Year: 2065, Storage: 100, Amount: 99},
  {Year: 2065, Storage: 250, Amount: 99},
  {Year: 2065, Storage: 500, Amount: 299},
  {Year: 2065, Storage: 750, Amount: 299},
  {Year: 2065, Storage: 1000, Amount: 399},
  {Year: 2065, Storage: 2000, Amount: 699},
  {Year: 2065, Storage: 3000, Amount: 1099},
  {Year: 2065, Storage: 5000, Amount: 1699},
  {Year: 2065, Storage: 8000, Amount: 2699},
  {Year: 2065, Storage: 12000, Amount: 3999},
  {Year: 2065, Storage: 20000, Amount: 6499},
  {Year: 2065, Storage: 50000, Amount: 16199},
  {Year: 2065, Storage: 100000, Amount: 32299},
  {Year: 2065, Storage: 250000, Amount: 80699},
  {Year: 2065, Storage: 500000, Amount: 161199},
  {Year: 2065, Storage: 750000, Amount: 241799},
  {Year: 2065, Storage: 1000000, Amount: 322399},
  {Year: 2065, Storage: 2000000, Amount: 644699},
  {Year: 2066, Storage: 100, Amount: 99},
  {Year: 2066, Storage: 250, Amount: 99},
  {Year: 2066, Storage: 500, Amount: 299},
  {Year: 2066, Storage: 750, Amount: 399},
  {Year: 2066, Storage: 1000, Amount: 399},
  {Year: 2066, Storage: 2000, Amount: 799},
  {Year: 2066, Storage: 3000, Amount: 1099},
  {Year: 2066, Storage: 5000, Amount: 1799},
  {Year: 2066, Storage: 8000, Amount: 2799},
  {Year: 2066, Storage: 12000, Amount: 4199},
  {Year: 2066, Storage: 20000, Amount: 6899},
  {Year: 2066, Storage: 50000, Amount: 16999},
  {Year: 2066, Storage: 100000, Amount: 33899},
  {Year: 2066, Storage: 250000, Amount: 84699},
  {Year: 2066, Storage: 500000, Amount: 169199},
  {Year: 2066, Storage: 750000, Amount: 253799},
  {Year: 2066, Storage: 1000000, Amount: 338299},
  {Year: 2066, Storage: 2000000, Amount: 676599},
  {Year: 2067, Storage: 100, Amount: 99},
  {Year: 2067, Storage: 250, Amount: 99},
  {Year: 2067, Storage: 500, Amount: 299},
  {Year: 2067, Storage: 750, Amount: 399},
  {Year: 2067, Storage: 1000, Amount: 499},
  {Year: 2067, Storage: 2000, Amount: 799},
  {Year: 2067, Storage: 3000, Amount: 1199},
  {Year: 2067, Storage: 5000, Amount: 1899},
  {Year: 2067, Storage: 8000, Amount: 2899},
  {Year: 2067, Storage: 12000, Amount: 4399},
  {Year: 2067, Storage: 20000, Amount: 7199},
  {Year: 2067, Storage: 50000, Amount: 17799},
  {Year: 2067, Storage: 100000, Amount: 35599},
  {Year: 2067, Storage: 250000, Amount: 88799},
  {Year: 2067, Storage: 500000, Amount: 177499},
  {Year: 2067, Storage: 750000, Amount: 266199},
  {Year: 2067, Storage: 1000000, Amount: 354899},
  {Year: 2067, Storage: 2000000, Amount: 709699},
  {Year: 2068, Storage: 100, Amount: 99},
  {Year: 2068, Storage: 250, Amount: 99},
  {Year: 2068, Storage: 500, Amount: 299},
  {Year: 2068, Storage: 750, Amount: 399},
  {Year: 2068, Storage: 1000, Amount: 499},
  {Year: 2068, Storage: 2000, Amount: 799},
  {Year: 2068, Storage: 3000, Amount: 1199},
  {Year: 2068, Storage: 5000, Amount: 1999},
  {Year: 2068, Storage: 8000, Amount: 3099},
  {Year: 2068, Storage: 12000, Amount: 4599},
  {Year: 2068, Storage: 20000, Amount: 7499},
  {Year: 2068, Storage: 50000, Amount: 18699},
  {Year: 2068, Storage: 100000, Amount: 37299},
  {Year: 2068, Storage: 250000, Amount: 93099},
  {Year: 2068, Storage: 500000, Amount: 186099},
  {Year: 2068, Storage: 750000, Amount: 279099},
  {Year: 2068, Storage: 1000000, Amount: 372099},
  {Year: 2068, Storage: 2000000, Amount: 743999},
  {Year: 2069, Storage: 100, Amount: 99},
  {Year: 2069, Storage: 250, Amount: 99},
  {Year: 2069, Storage: 500, Amount: 299},
  {Year: 2069, Storage: 750, Amount: 399},
  {Year: 2069, Storage: 1000, Amount: 499},
  {Year: 2069, Storage: 2000, Amount: 899},
  {Year: 2069, Storage: 3000, Amount: 1299},
  {Year: 2069, Storage: 5000, Amount: 1999},
  {Year: 2069, Storage: 8000, Amount: 3199},
  {Year: 2069, Storage: 12000, Amount: 4799},
  {Year: 2069, Storage: 20000, Amount: 7899},
  {Year: 2069, Storage: 50000, Amount: 19599},
  {Year: 2069, Storage: 100000, Amount: 39099},
  {Year: 2069, Storage: 250000, Amount: 97499},
  {Year: 2069, Storage: 500000, Amount: 194999},
  {Year: 2069, Storage: 750000, Amount: 292399},
  {Year: 2069, Storage: 1000000, Amount: 389899},
  {Year: 2069, Storage: 2000000, Amount: 779599},
  {Year: 2070, Storage: 100, Amount: 99},
  {Year: 2070, Storage: 250, Amount: 199},
  {Year: 2070, Storage: 500, Amount: 299},
  {Year: 2070, Storage: 750, Amount: 399},
  {Year: 2070, Storage: 1000, Amount: 499},
  {Year: 2070, Storage: 2000, Amount: 899},
  {Year: 2070, Storage: 3000, Amount: 1299},
  {Year: 2070, Storage: 5000, Amount: 2099},
  {Year: 2070, Storage: 8000, Amount: 3399},
  {Year: 2070, Storage: 12000, Amount: 4999},
  {Year: 2070, Storage: 20000, Amount: 8299},
  {Year: 2070, Storage: 50000, Amount: 20499},
  {Year: 2070, Storage: 100000, Amount: 40899},
  {Year: 2070, Storage: 250000, Amount: 102199},
  {Year: 2070, Storage: 500000, Amount: 204199},
  {Year: 2070, Storage: 750000, Amount: 306299},
  {Year: 2070, Storage: 1000000, Amount: 408299},
  {Year: 2070, Storage: 2000000, Amount: 816599},
  {Year: 2071, Storage: 100, Amount: 99},
  {Year: 2071, Storage: 250, Amount: 199},
  {Year: 2071, Storage: 500, Amount: 299},
  {Year: 2071, Storage: 750, Amount: 399},
  {Year: 2071, Storage: 1000, Amount: 499},
  {Year: 2071, Storage: 2000, Amount: 999},
  {Year: 2071, Storage: 3000, Amount: 1399},
  {Year: 2071, Storage: 5000, Amount: 2199},
  {Year: 2071, Storage: 8000, Amount: 3499},
  {Year: 2071, Storage: 12000, Amount: 5199},
  {Year: 2071, Storage: 20000, Amount: 8599},
  {Year: 2071, Storage: 50000, Amount: 21499},
  {Year: 2071, Storage: 100000, Amount: 42799},
  {Year: 2071, Storage: 250000, Amount: 106899},
  {Year: 2071, Storage: 500000, Amount: 213799},
  {Year: 2071, Storage: 750000, Amount: 320599},
  {Year: 2071, Storage: 1000000, Amount: 427499},
  {Year: 2071, Storage: 2000000, Amount: 854799},
  {Year: 2072, Storage: 100, Amount: 99},
  {Year: 2072, Storage: 250, Amount: 199},
  {Year: 2072, Storage: 500, Amount: 299},
  {Year: 2072, Storage: 750, Amount: 399},
  {Year: 2072, Storage: 1000, Amount: 499},
  {Year: 2072, Storage: 2000, Amount: 999},
  {Year: 2072, Storage: 3000, Amount: 1399},
  {Year: 2072, Storage: 5000, Amount: 2299},
  {Year: 2072, Storage: 8000, Amount: 3699},
  {Year: 2072, Storage: 12000, Amount: 5499},
  {Year: 2072, Storage: 20000, Amount: 8999},
  {Year: 2072, Storage: 50000, Amount: 22499},
  {Year: 2072, Storage: 100000, Amount: 44799},
  {Year: 2072, Storage: 250000, Amount: 111899},
  {Year: 2072, Storage: 500000, Amount: 223699},
  {Year: 2072, Storage: 750000, Amount: 335499},
  {Year: 2072, Storage: 1000000, Amount: 447299},
  {Year: 2072, Storage: 2000000, Amount: 894499},
  {Year: 2073, Storage: 100, Amount: 99},
  {Year: 2073, Storage: 250, Amount: 199},
  {Year: 2073, Storage: 500, Amount: 299},
  {Year: 2073, Storage: 750, Amount: 499},
  {Year: 2073, Storage: 1000, Amount: 599},
  {Year: 2073, Storage: 2000, Amount: 999},
  {Year: 2073, Storage: 3000, Amount: 1499},
  {Year: 2073, Storage: 5000, Amount: 2399},
  {Year: 2073, Storage: 8000, Amount: 3799},
  {Year: 2073, Storage: 12000, Amount: 5699},
  {Year: 2073, Storage: 20000, Amount: 9499},
  {Year: 2073, Storage: 50000, Amount: 23499},
  {Year: 2073, Storage: 100000, Amount: 46899},
  {Year: 2073, Storage: 250000, Amount: 116999},
  {Year: 2073, Storage: 500000, Amount: 233999},
  {Year: 2073, Storage: 750000, Amount: 350899},
  {Year: 2073, Storage: 1000000, Amount: 467899},
  {Year: 2073, Storage: 2000000, Amount: 935699},
  {Year: 2074, Storage: 100, Amount: 99},
  {Year: 2074, Storage: 250, Amount: 199},
  {Year: 2074, Storage: 500, Amount: 299},
  {Year: 2074, Storage: 750, Amount: 499},
  {Year: 2074, Storage: 1000, Amount: 599},
  {Year: 2074, Storage: 2000, Amount: 1099},
  {Year: 2074, Storage: 3000, Amount: 1599},
  {Year: 2074, Storage: 5000, Amount: 2499},
  {Year: 2074, Storage: 8000, Amount: 3999},
  {Year: 2074, Storage: 12000, Amount: 5999},
  {Year: 2074, Storage: 20000, Amount: 9899},
  {Year: 2074, Storage: 50000, Amount: 24599},
  {Year: 2074, Storage: 100000, Amount: 48999},
  {Year: 2074, Storage: 250000, Amount: 122399},
  {Year: 2074, Storage: 500000, Amount: 244699},
  {Year: 2074, Storage: 750000, Amount: 366899},
  {Year: 2074, Storage: 1000000, Amount: 489199},
  {Year: 2074, Storage: 2000000, Amount: 978299},
  {Year: 2075, Storage: 100, Amount: 99},
  {Year: 2075, Storage: 250, Amount: 199},
  {Year: 2075, Storage: 500, Amount: 399},
  {Year: 2075, Storage: 750, Amount: 499},
  {Year: 2075, Storage: 1000, Amount: 599},
  {Year: 2075, Storage: 2000, Amount: 1099},
  {Year: 2075, Storage: 3000, Amount: 1599},
  {Year: 2075, Storage: 5000, Amount: 2699},
  {Year: 2075, Storage: 8000, Amount: 4199},
  {Year: 2075, Storage: 12000, Amount: 6199},
  {Year: 2075, Storage: 20000, Amount: 10299},
  {Year: 2075, Storage: 50000, Amount: 25699},
  {Year: 2075, Storage: 100000, Amount: 51199},
  {Year: 2075, Storage: 250000, Amount: 127899},
  {Year: 2075, Storage: 500000, Amount: 255699},
  {Year: 2075, Storage: 750000, Amount: 383499},
  {Year: 2075, Storage: 1000000, Amount: 511299},
  {Year: 2075, Storage: 2000000, Amount: 1022499},
  {Year: 2076, Storage: 100, Amount: 99},
  {Year: 2076, Storage: 250, Amount: 199},
  {Year: 2076, Storage: 500, Amount: 399},
  {Year: 2076, Storage: 750, Amount: 499},
  {Year: 2076, Storage: 1000, Amount: 599},
  {Year: 2076, Storage: 2000, Amount: 1199},
  {Year: 2076, Storage: 3000, Amount: 1699},
  {Year: 2076, Storage: 5000, Amount: 2799},
  {Year: 2076, Storage: 8000, Amount: 4399},
  {Year: 2076, Storage: 12000, Amount: 6499},
  {Year: 2076, Storage: 20000, Amount: 10799},
  {Year: 2076, Storage: 50000, Amount: 26799},
  {Year: 2076, Storage: 100000, Amount: 53499},
  {Year: 2076, Storage: 250000, Amount: 133599},
  {Year: 2076, Storage: 500000, Amount: 267099},
  {Year: 2076, Storage: 750000, Amount: 400699},
  {Year: 2076, Storage: 1000000, Amount: 534199},
  {Year: 2076, Storage: 2000000, Amount: 1068299},
  {Year: 2077, Storage: 100, Amount: 99},
  {Year: 2077, Storage: 250, Amount: 199},
  {Year: 2077, Storage: 500, Amount: 399},
  {Year: 2077, Storage: 750, Amount: 499},
  {Year: 2077, Storage: 1000, Amount: 699},
  {Year: 2077, Storage: 2000, Amount: 1199},
  {Year: 2077, Storage: 3000, Amount: 1799},
  {Year: 2077, Storage: 5000, Amount: 2899},
  {Year: 2077, Storage: 8000, Amount: 4599},
  {Year: 2077, Storage: 12000, Amount: 6799},
  {Year: 2077, Storage: 20000, Amount: 11299},
  {Year: 2077, Storage: 50000, Amount: 27999},
  {Year: 2077, Storage: 100000, Amount: 55899},
  {Year: 2077, Storage: 250000, Amount: 139499},
  {Year: 2077, Storage: 500000, Amount: 278999},
  {Year: 2077, Storage: 750000, Amount: 418399},
  {Year: 2077, Storage: 1000000, Amount: 557899},
  {Year: 2077, Storage: 2000000, Amount: 1115699},
  {Year: 2078, Storage: 100, Amount: 99},
  {Year: 2078, Storage: 250, Amount: 199},
  {Year: 2078, Storage: 500, Amount: 399},
  {Year: 2078, Storage: 750, Amount: 499},
  {Year: 2078, Storage: 1000, Amount: 699},
  {Year: 2078, Storage: 2000, Amount: 1299},
  {Year: 2078, Storage: 3000, Amount: 1799},
  {Year: 2078, Storage: 5000, Amount: 2999},
  {Year: 2078, Storage: 8000, Amount: 4799},
  {Year: 2078, Storage: 12000, Amount: 7099},
  {Year: 2078, Storage: 20000, Amount: 11699},
  {Year: 2078, Storage: 50000, Amount: 29199},
  {Year: 2078, Storage: 100000, Amount: 58299},
  {Year: 2078, Storage: 250000, Amount: 145699},
  {Year: 2078, Storage: 500000, Amount: 291299},
  {Year: 2078, Storage: 750000, Amount: 436899},
  {Year: 2078, Storage: 1000000, Amount: 582499},
  {Year: 2078, Storage: 2000000, Amount: 1164799},
  {Year: 2079, Storage: 100, Amount: 99},
  {Year: 2079, Storage: 250, Amount: 299},
  {Year: 2079, Storage: 500, Amount: 399},
  {Year: 2079, Storage: 750, Amount: 599},
  {Year: 2079, Storage: 1000, Amount: 699},
  {Year: 2079, Storage: 2000, Amount: 1299},
  {Year: 2079, Storage: 3000, Amount: 1899},
  {Year: 2079, Storage: 5000, Amount: 3099},
  {Year: 2079, Storage: 8000, Amount: 4999},
  {Year: 2079, Storage: 12000, Amount: 7399},
  {Year: 2079, Storage: 20000, Amount: 12299},
  {Year: 2079, Storage: 50000, Amount: 30499},
  {Year: 2079, Storage: 100000, Amount: 60899},
  {Year: 2079, Storage: 250000, Amount: 152099},
  {Year: 2079, Storage: 500000, Amount: 303999},
  {Year: 2079, Storage: 750000, Amount: 455999},
  {Year: 2079, Storage: 1000000, Amount: 607899},
  {Year: 2079, Storage: 2000000, Amount: 1215699},
  {Year: 2080, Storage: 100, Amount: 99},
  {Year: 2080, Storage: 250, Amount: 299},
  {Year: 2080, Storage: 500, Amount: 399},
  {Year: 2080, Storage: 750, Amount: 599},
  {Year: 2080, Storage: 1000, Amount: 699},
  {Year: 2080, Storage: 2000, Amount: 1399},
  {Year: 2080, Storage: 3000, Amount: 1999},
  {Year: 2080, Storage: 5000, Amount: 3299},
  {Year: 2080, Storage: 8000, Amount: 5199},
  {Year: 2080, Storage: 12000, Amount: 7699},
  {Year: 2080, Storage: 20000, Amount: 12799},
  {Year: 2080, Storage: 50000, Amount: 31799},
  {Year: 2080, Storage: 100000, Amount: 63499},
  {Year: 2080, Storage: 250000, Amount: 158599},
  {Year: 2080, Storage: 500000, Amount: 317199},
  {Year: 2080, Storage: 750000, Amount: 475699},
  {Year: 2080, Storage: 1000000, Amount: 634299},
  {Year: 2080, Storage: 2000000, Amount: 1268399},
  {Year: 2081, Storage: 100, Amount: 99},
  {Year: 2081, Storage: 250, Amount: 299},
  {Year: 2081, Storage: 500, Amount: 399},
  {Year: 2081, Storage: 750, Amount: 599},
  {Year: 2081, Storage: 1000, Amount: 799},
  {Year: 2081, Storage: 2000, Amount: 1399},
  {Year: 2081, Storage: 3000, Amount: 2099},
  {Year: 2081, Storage: 5000, Amount: 3399},
  {Year: 2081, Storage: 8000, Amount: 5399},
  {Year: 2081, Storage: 12000, Amount: 7999},
  {Year: 2081, Storage: 20000, Amount: 13299},
  {Year: 2081, Storage: 50000, Amount: 33199},
  {Year: 2081, Storage: 100000, Amount: 66199},
  {Year: 2081, Storage: 250000, Amount: 165499},
  {Year: 2081, Storage: 500000, Amount: 330799},
  {Year: 2081, Storage: 750000, Amount: 496199},
  {Year: 2081, Storage: 1000000, Amount: 661599},
  {Year: 2081, Storage: 2000000, Amount: 1322999},
  {Year: 2082, Storage: 100, Amount: 99},
  {Year: 2082, Storage: 250, Amount: 299},
  {Year: 2082, Storage: 500, Amount: 399},
  {Year: 2082, Storage: 750, Amount: 599},
  {Year: 2082, Storage: 1000, Amount: 799},
  {Year: 2082, Storage: 2000, Amount: 1499},
  {Year: 2082, Storage: 3000, Amount: 2199},
  {Year: 2082, Storage: 5000, Amount: 3499},
  {Year: 2082, Storage: 8000, Amount: 5599},
  {Year: 2082, Storage: 12000, Amount: 8399},
  {Year: 2082, Storage: 20000, Amount: 13899},
  {Year: 2082, Storage: 50000, Amount: 34599},
  {Year: 2082, Storage: 100000, Amount: 69099},
  {Year: 2082, Storage: 250000, Amount: 172499},
  {Year: 2082, Storage: 500000, Amount: 344999},
  {Year: 2082, Storage: 750000, Amount: 517399},
  {Year: 2082, Storage: 1000000, Amount: 689799},
  {Year: 2082, Storage: 2000000, Amount: 1379599},
  {Year: 2083, Storage: 100, Amount: 99},
  {Year: 2083, Storage: 250, Amount: 299},
  {Year: 2083, Storage: 500, Amount: 499},
  {Year: 2083, Storage: 750, Amount: 599},
  {Year: 2083, Storage: 1000, Amount: 799},
  {Year: 2083, Storage: 2000, Amount: 1499},
  {Year: 2083, Storage: 3000, Amount: 2299},
  {Year: 2083, Storage: 5000, Amount: 3699},
  {Year: 2083, Storage: 8000, Amount: 5899},
  {Year: 2083, Storage: 12000, Amount: 8699},
  {Year: 2083, Storage: 20000, Amount: 14499},
  {Year: 2083, Storage: 50000, Amount: 35999},
  {Year: 2083, Storage: 100000, Amount: 71999},
  {Year: 2083, Storage: 250000, Amount: 179799},
  {Year: 2083, Storage: 500000, Amount: 359599},
  {Year: 2083, Storage: 750000, Amount: 539299},
  {Year: 2083, Storage: 1000000, Amount: 719099},
  {Year: 2083, Storage: 2000000, Amount: 1438099},
  {Year: 2084, Storage: 100, Amount: 99},
  {Year: 2084, Storage: 250, Amount: 299},
  {Year: 2084, Storage: 500, Amount: 499},
  {Year: 2084, Storage: 750, Amount: 699},
  {Year: 2084, Storage: 1000, Amount: 799},
  {Year: 2084, Storage: 2000, Amount: 1599},
  {Year: 2084, Storage: 3000, Amount: 2299},
  {Year: 2084, Storage: 5000, Amount: 3799},
  {Year: 2084, Storage: 8000, Amount: 6099},
  {Year: 2084, Storage: 12000, Amount: 9099},
  {Year: 2084, Storage: 20000, Amount: 15099},
  {Year: 2084, Storage: 50000, Amount: 37599},
  {Year: 2084, Storage: 100000, Amount: 74999},
  {Year: 2084, Storage: 250000, Amount: 187399},
  {Year: 2084, Storage: 500000, Amount: 374699},
  {Year: 2084, Storage: 750000, Amount: 562099},
  {Year: 2084, Storage: 1000000, Amount: 749399},
  {Year: 2084, Storage: 2000000, Amount: 1498699},
  {Year: 2085, Storage: 100, Amount: 99},
  {Year: 2085, Storage: 250, Amount: 299},
  {Year: 2085, Storage: 500, Amount: 499},
  {Year: 2085, Storage: 750, Amount: 699},
  {Year: 2085, Storage: 1000, Amount: 899},
  {Year: 2085, Storage: 2000, Amount: 1699},
  {Year: 2085, Storage: 3000, Amount: 2399},
  {Year: 2085, Storage: 5000, Amount: 3999},
  {Year: 2085, Storage: 8000, Amount: 6299},
  {Year: 2085, Storage: 12000, Amount: 9499},
  {Year: 2085, Storage: 20000, Amount: 15699},
  {Year: 2085, Storage: 50000, Amount: 39099},
  {Year: 2085, Storage: 100000, Amount: 78199},
  {Year: 2085, Storage: 250000, Amount: 195299},
  {Year: 2085, Storage: 500000, Amount: 390399},
  {Year: 2085, Storage: 750000, Amount: 585599},
  {Year: 2085, Storage: 1000000, Amount: 780699},
  {Year: 2085, Storage: 2000000, Amount: 1561399},
  {Year: 2086, Storage: 100, Amount: 99},
  {Year: 2086, Storage: 250, Amount: 299},
  {Year: 2086, Storage: 500, Amount: 499},
  {Year: 2086, Storage: 750, Amount: 699},
  {Year: 2086, Storage: 1000, Amount: 899},
  {Year: 2086, Storage: 2000, Amount: 1699},
  {Year: 2086, Storage: 3000, Amount: 2499},
  {Year: 2086, Storage: 5000, Amount: 4199},
  {Year: 2086, Storage: 8000, Amount: 6599},
  {Year: 2086, Storage: 12000, Amount: 9899},
  {Year: 2086, Storage: 20000, Amount: 16399},
  {Year: 2086, Storage: 50000, Amount: 40799},
  {Year: 2086, Storage: 100000, Amount: 81399},
  {Year: 2086, Storage: 250000, Amount: 203399},
  {Year: 2086, Storage: 500000, Amount: 406699},
  {Year: 2086, Storage: 750000, Amount: 609899},
  {Year: 2086, Storage: 1000000, Amount: 813199},
  {Year: 2086, Storage: 2000000, Amount: 1626299},
  {Year: 2087, Storage: 100, Amount: 99},
  {Year: 2087, Storage: 250, Amount: 299},
  {Year: 2087, Storage: 500, Amount: 499},
  {Year: 2087, Storage: 750, Amount: 699},
  {Year: 2087, Storage: 1000, Amount: 899},
  {Year: 2087, Storage: 2000, Amount: 1799},
  {Year: 2087, Storage: 3000, Amount: 2599},
  {Year: 2087, Storage: 5000, Amount: 4299},
  {Year: 2087, Storage: 8000, Amount: 6899},
  {Year: 2087, Storage: 12000, Amount: 10299},
  {Year: 2087, Storage: 20000, Amount: 16999},
  {Year: 2087, Storage: 50000, Amount: 42399},
  {Year: 2087, Storage: 100000, Amount: 84799},
  {Year: 2087, Storage: 250000, Amount: 211799},
  {Year: 2087, Storage: 500000, Amount: 423399},
  {Year: 2087, Storage: 750000, Amount: 635099},
  {Year: 2087, Storage: 1000000, Amount: 846799},
  {Year: 2087, Storage: 2000000, Amount: 1693499},
  {Year: 2088, Storage: 100, Amount: 99},
  {Year: 2088, Storage: 250, Amount: 299},
  {Year: 2088, Storage: 500, Amount: 499},
  {Year: 2088, Storage: 750, Amount: 799},
  {Year: 2088, Storage: 1000, Amount: 999},
  {Year: 2088, Storage: 2000, Amount: 1899},
  {Year: 2088, Storage: 3000, Amount: 2699},
  {Year: 2088, Storage: 5000, Amount: 4499},
  {Year: 2088, Storage: 8000, Amount: 7199},
  {Year: 2088, Storage: 12000, Amount: 10699},
  {Year: 2088, Storage: 20000, Amount: 17699},
  {Year: 2088, Storage: 50000, Amount: 44199},
  {Year: 2088, Storage: 100000, Amount: 88199},
  {Year: 2088, Storage: 250000, Amount: 220499},
  {Year: 2088, Storage: 500000, Amount: 440799},
  {Year: 2088, Storage: 750000, Amount: 661199},
  {Year: 2088, Storage: 1000000, Amount: 881599},
  {Year: 2088, Storage: 2000000, Amount: 1762999},
  {Year: 2089, Storage: 100, Amount: 99},
  {Year: 2089, Storage: 250, Amount: 299},
  {Year: 2089, Storage: 500, Amount: 599},
  {Year: 2089, Storage: 750, Amount: 799},
  {Year: 2089, Storage: 1000, Amount: 999},
  {Year: 2089, Storage: 2000, Amount: 1899},
  {Year: 2089, Storage: 3000, Amount: 2899},
  {Year: 2089, Storage: 5000, Amount: 4699},
  {Year: 2089, Storage: 8000, Amount: 7399},
  {Year: 2089, Storage: 12000, Amount: 11099},
  {Year: 2089, Storage: 20000, Amount: 18399},
  {Year: 2089, Storage: 50000, Amount: 45999},
  {Year: 2089, Storage: 100000, Amount: 91799},
  {Year: 2089, Storage: 250000, Amount: 229499},
  {Year: 2089, Storage: 500000, Amount: 458799},
  {Year: 2089, Storage: 750000, Amount: 688199},
  {Year: 2089, Storage: 1000000, Amount: 917499},
  {Year: 2089, Storage: 2000000, Amount: 1834999},
  {Year: 2090, Storage: 100, Amount: 99},
  {Year: 2090, Storage: 250, Amount: 299},
  {Year: 2090, Storage: 500, Amount: 599},
  {Year: 2090, Storage: 750, Amount: 799},
  {Year: 2090, Storage: 1000, Amount: 1099},
  {Year: 2090, Storage: 2000, Amount: 1999},
  {Year: 2090, Storage: 3000, Amount: 2999},
  {Year: 2090, Storage: 5000, Amount: 4899},
  {Year: 2090, Storage: 8000, Amount: 7699},
  {Year: 2090, Storage: 12000, Amount: 11599},
  {Year: 2090, Storage: 20000, Amount: 19199},
  {Year: 2090, Storage: 50000, Amount: 47799},
  {Year: 2090, Storage: 100000, Amount: 95599},
  {Year: 2090, Storage: 250000, Amount: 238799},
  {Year: 2090, Storage: 500000, Amount: 477399},
  {Year: 2090, Storage: 750000, Amount: 716099},
  {Year: 2090, Storage: 1000000, Amount: 954799},
  {Year: 2090, Storage: 2000000, Amount: 1909399},
  {Year: 2091, Storage: 100, Amount: 99},
  {Year: 2091, Storage: 250, Amount: 299},
  {Year: 2091, Storage: 500, Amount: 599},
  {Year: 2091, Storage: 750, Amount: 799},
  {Year: 2091, Storage: 1000, Amount: 1099},
  {Year: 2091, Storage: 2000, Amount: 2099},
  {Year: 2091, Storage: 3000, Amount: 3099},
  {Year: 2091, Storage: 5000, Amount: 5099},
  {Year: 2091, Storage: 8000, Amount: 7999},
  {Year: 2091, Storage: 12000, Amount: 11999},
  {Year: 2091, Storage: 20000, Amount: 19999},
  {Year: 2091, Storage: 50000, Amount: 49799},
  {Year: 2091, Storage: 100000, Amount: 99399},
  {Year: 2091, Storage: 250000, Amount: 248399},
  {Year: 2091, Storage: 500000, Amount: 496699},
  {Year: 2091, Storage: 750000, Amount: 744999},
  {Year: 2091, Storage: 1000000, Amount: 993299},
  {Year: 2091, Storage: 2000000, Amount: 1986399},
  {Year: 2092, Storage: 100, Amount: 199},
  {Year: 2092, Storage: 250, Amount: 399},
  {Year: 2092, Storage: 500, Amount: 599},
  {Year: 2092, Storage: 750, Amount: 899},
  {Year: 2092, Storage: 1000, Amount: 1099},
  {Year: 2092, Storage: 2000, Amount: 2199},
  {Year: 2092, Storage: 3000, Amount: 3199},
  {Year: 2092, Storage: 5000, Amount: 5299},
  {Year: 2092, Storage: 8000, Amount: 8399},
  {Year: 2092, Storage: 12000, Amount: 12499},
  {Year: 2092, Storage: 20000, Amount: 20799},
  {Year: 2092, Storage: 50000, Amount: 51799},
  {Year: 2092, Storage: 100000, Amount: 103399},
  {Year: 2092, Storage: 250000, Amount: 258399},
  {Year: 2092, Storage: 500000, Amount: 516599},
  {Year: 2092, Storage: 750000, Amount: 774899},
  {Year: 2092, Storage: 1000000, Amount: 1033099},
  {Year: 2092, Storage: 2000000, Amount: 2066099},
  {Year: 2093, Storage: 100, Amount: 199},
  {Year: 2093, Storage: 250, Amount: 399},
  {Year: 2093, Storage: 500, Amount: 599},
  {Year: 2093, Storage: 750, Amount: 899},
  {Year: 2093, Storage: 1000, Amount: 1199},
  {Year: 2093, Storage: 2000, Amount: 2199},
  {Year: 2093, Storage: 3000, Amount: 3299},
  {Year: 2093, Storage: 5000, Amount: 5499},
  {Year: 2093, Storage: 8000, Amount: 8699},
  {Year: 2093, Storage: 12000, Amount: 12999},
  {Year: 2093, Storage: 20000, Amount: 21599},
  {Year: 2093, Storage: 50000, Amount: 53799},
  {Year: 2093, Storage: 100000, Amount: 107499},
  {Year: 2093, Storage: 250000, Amount: 268699},
  {Year: 2093, Storage: 500000, Amount: 537199},
  {Year: 2093, Storage: 750000, Amount: 805799},
  {Year: 2093, Storage: 1000000, Amount: 1074299},
  {Year: 2093, Storage: 2000000, Amount: 2148499},
  {Year: 2094, Storage: 100, Amount: 199},
  {Year: 2094, Storage: 250, Amount: 399},
  {Year: 2094, Storage: 500, Amount: 699},
  {Year: 2094, Storage: 750, Amount: 899},
  {Year: 2094, Storage: 1000, Amount: 1199},
  {Year: 2094, Storage: 2000, Amount: 2299},
  {Year: 2094, Storage: 3000, Amount: 3499},
  {Year: 2094, Storage: 5000, Amount: 5699},
  {Year: 2094, Storage: 8000, Amount: 8999},
  {Year: 2094, Storage: 12000, Amount: 13499},
  {Year: 2094, Storage: 20000, Amount: 22399},
  {Year: 2094, Storage: 50000, Amount: 55899},
  {Year: 2094, Storage: 100000, Amount: 111799},
  {Year: 2094, Storage: 250000, Amount: 279299},
  {Year: 2094, Storage: 500000, Amount: 558499},
  {Year: 2094, Storage: 750000, Amount: 837699},
  {Year: 2094, Storage: 1000000, Amount: 1116899},
  {Year: 2094, Storage: 2000000, Amount: 2233799},
  {Year: 2095, Storage: 100, Amount: 199},
  {Year: 2095, Storage: 250, Amount: 399},
  {Year: 2095, Storage: 500, Amount: 699},
  {Year: 2095, Storage: 750, Amount: 999},
  {Year: 2095, Storage: 1000, Amount: 1299},
  {Year: 2095, Storage: 2000, Amount: 2399},
  {Year: 2095, Storage: 3000, Amount: 3599},
  {Year: 2095, Storage: 5000, Amount: 5899},
  {Year: 2095, Storage: 8000, Amount: 9399},
  {Year: 2095, Storage: 12000, Amount: 13999},
  {Year: 2095, Storage: 20000, Amount: 23299},
  {Year: 2095, Storage: 50000, Amount: 58099},
  {Year: 2095, Storage: 100000, Amount: 116199},
  {Year: 2095, Storage: 250000, Amount: 290299},
  {Year: 2095, Storage: 500000, Amount: 580599},
  {Year: 2095, Storage: 750000, Amount: 870799},
  {Year: 2095, Storage: 1000000, Amount: 1160999},
  {Year: 2095, Storage: 2000000, Amount: 2321999},
  {Year: 2096, Storage: 100, Amount: 199},
  {Year: 2096, Storage: 250, Amount: 399},
  {Year: 2096, Storage: 500, Amount: 699},
  {Year: 2096, Storage: 750, Amount: 999},
  {Year: 2096, Storage: 1000, Amount: 1299},
  {Year: 2096, Storage: 2000, Amount: 2499},
  {Year: 2096, Storage: 3000, Amount: 3699},
  {Year: 2096, Storage: 5000, Amount: 6099},
  {Year: 2096, Storage: 8000, Amount: 9799},
  {Year: 2096, Storage: 12000, Amount: 14599},
  {Year: 2096, Storage: 20000, Amount: 24199},
  {Year: 2096, Storage: 50000, Amount: 60399},
  {Year: 2096, Storage: 100000, Amount: 120799},
  {Year: 2096, Storage: 250000, Amount: 301699},
  {Year: 2096, Storage: 500000, Amount: 603399},
  {Year: 2096, Storage: 750000, Amount: 904999},
  {Year: 2096, Storage: 1000000, Amount: 1206599},
  {Year: 2096, Storage: 2000000, Amount: 2413099},
  {Year: 2097, Storage: 100, Amount: 199},
  {Year: 2097, Storage: 250, Amount: 399},
  {Year: 2097, Storage: 500, Amount: 699},
  {Year: 2097, Storage: 750, Amount: 999},
  {Year: 2097, Storage: 1000, Amount: 1399},
  {Year: 2097, Storage: 2000, Amount: 2599},
  {Year: 2097, Storage: 3000, Amount: 3899},
  {Year: 2097, Storage: 5000, Amount: 6399},
  {Year: 2097, Storage: 8000, Amount: 10099},
  {Year: 2097, Storage: 12000, Amount: 15099},
  {Year: 2097, Storage: 20000, Amount: 25199},
  {Year: 2097, Storage: 50000, Amount: 62799},
  {Year: 2097, Storage: 100000, Amount: 125499},
  {Year: 2097, Storage: 250000, Amount: 313499},
  {Year: 2097, Storage: 500000, Amount: 626899},
  {Year: 2097, Storage: 750000, Amount: 940399},
  {Year: 2097, Storage: 1000000, Amount: 1253799},
  {Year: 2097, Storage: 2000000, Amount: 2507399},
  {Year: 2098, Storage: 100, Amount: 199},
  {Year: 2098, Storage: 250, Amount: 399},
  {Year: 2098, Storage: 500, Amount: 799},
  {Year: 2098, Storage: 750, Amount: 1099},
  {Year: 2098, Storage: 1000, Amount: 1399},
  {Year: 2098, Storage: 2000, Amount: 2699},
  {Year: 2098, Storage: 3000, Amount: 3999},
  {Year: 2098, Storage: 5000, Amount: 6599},
  {Year: 2098, Storage: 8000, Amount: 10499},
  {Year: 2098, Storage: 12000, Amount: 15699},
  {Year: 2098, Storage: 20000, Amount: 26099},
  {Year: 2098, Storage: 50000, Amount: 65199},
  {Year: 2098, Storage: 100000, Amount: 130299},
  {Year: 2098, Storage: 250000, Amount: 325699},
  {Year: 2098, Storage: 500000, Amount: 651299},
  {Year: 2098, Storage: 750000, Amount: 976899},
  {Year: 2098, Storage: 1000000, Amount: 1302499},
  {Year: 2098, Storage: 2000000, Amount: 2604899},
  {Year: 2099, Storage: 100, Amount: 199},
  {Year: 2099, Storage: 250, Amount: 399},
  {Year: 2099, Storage: 500, Amount: 799},
  {Year: 2099, Storage: 750, Amount: 1099},
  {Year: 2099, Storage: 1000, Amount: 1499},
  {Year: 2099, Storage: 2000, Amount: 2799},
  {Year: 2099, Storage: 3000, Amount: 4199},
  {Year: 2099, Storage: 5000, Amount: 6899},
  {Year: 2099, Storage: 8000, Amount: 10899},
  {Year: 2099, Storage: 12000, Amount: 16299},
  {Year: 2099, Storage: 20000, Amount: 27199},
  {Year: 2099, Storage: 50000, Amount: 67699},
  {Year: 2099, Storage: 100000, Amount: 135399},
  {Year: 2099, Storage: 250000, Amount: 338299},
  {Year: 2099, Storage: 500000, Amount: 676499},
  {Year: 2099, Storage: 750000, Amount: 1014699},
  {Year: 2099, Storage: 1000000, Amount: 1352899},
  {Year: 2099, Storage: 2000000, Amount: 2705699},
  {Year: 2100, Storage: 100, Amount: 199},
  {Year: 2100, Storage: 250, Amount: 499},
  {Year: 2100, Storage: 500, Amount: 799},
  {Year: 2100, Storage: 750, Amount: 1199},
  {Year: 2100, Storage: 1000, Amount: 1499},
  {Year: 2100, Storage: 2000, Amount: 2899},
  {Year: 2100, Storage: 3000, Amount: 4299},
  {Year: 2100, Storage: 5000, Amount: 7099},
  {Year: 2100, Storage: 8000, Amount: 11299},
  {Year: 2100, Storage: 12000, Amount: 16999},
  {Year: 2100, Storage: 20000, Amount: 28199},
  {Year: 2100, Storage: 50000, Amount: 70299},
  {Year: 2100, Storage: 100000, Amount: 140599},
  {Year: 2100, Storage: 250000, Amount: 351299},
  {Year: 2100, Storage: 500000, Amount: 702599},
  {Year: 2100, Storage: 750000, Amount: 1053799},
  {Year: 2100, Storage: 1000000, Amount: 1404999},
  {Year: 2100, Storage: 2000000, Amount: 2809999},
  {Year: 2101, Storage: 100, Amount: 199},
  {Year: 2101, Storage: 250, Amount: 499},
  {Year: 2101, Storage: 500, Amount: 799},
  {Year: 2101, Storage: 750, Amount: 1199},
  {Year: 2101, Storage: 1000, Amount: 1599},
  {Year: 2101, Storage: 2000, Amount: 2999},
  {Year: 2101, Storage: 3000, Amount: 4499},
  {Year: 2101, Storage: 5000, Amount: 7399},
  {Year: 2101, Storage: 8000, Amount: 11799},
  {Year: 2101, Storage: 12000, Amount: 17599},
  {Year: 2101, Storage: 20000, Amount: 29299},
  {Year: 2101, Storage: 50000, Amount: 72999},
  {Year: 2101, Storage: 100000, Amount: 145999},
  {Year: 2101, Storage: 250000, Amount: 364799},
  {Year: 2101, Storage: 500000, Amount: 729499},
  {Year: 2101, Storage: 750000, Amount: 1094199},
  {Year: 2101, Storage: 1000000, Amount: 1458899},
  {Year: 2101, Storage: 2000000, Amount: 2917699},
  {Year: 2102, Storage: 100, Amount: 299},
  {Year: 2102, Storage: 250, Amount: 499},
  {Year: 2102, Storage: 500, Amount: 899},
  {Year: 2102, Storage: 750, Amount: 1199},
  {Year: 2102, Storage: 1000, Amount: 1599},
  {Year: 2102, Storage: 2000, Amount: 3099},
  {Year: 2102, Storage: 3000, Amount: 4599},
  {Year: 2102, Storage: 5000, Amount: 7699},
  {Year: 2102, Storage: 8000, Amount: 12199},
  {Year: 2102, Storage: 12000, Amount: 18299},
  {Year: 2102, Storage: 20000, Amount: 30399},
  {Year: 2102, Storage: 50000, Amount: 75799},
  {Year: 2102, Storage: 100000, Amount: 151499},
  {Year: 2102, Storage: 250000, Amount: 378699},
  {Year: 2102, Storage: 500000, Amount: 757299},
  {Year: 2102, Storage: 750000, Amount: 1135999},
  {Year: 2102, Storage: 1000000, Amount: 1514599},
  {Year: 2102, Storage: 2000000, Amount: 3029099},
  {Year: 2103, Storage: 100, Amount: 299},
  {Year: 2103, Storage: 250, Amount: 499},
  {Year: 2103, Storage: 500, Amount: 899},
  {Year: 2103, Storage: 750, Amount: 1299},
  {Year: 2103, Storage: 1000, Amount: 1699},
  {Year: 2103, Storage: 2000, Amount: 3199},
  {Year: 2103, Storage: 3000, Amount: 4799},
  {Year: 2103, Storage: 5000, Amount: 7999},
  {Year: 2103, Storage: 8000, Amount: 12699},
  {Year: 2103, Storage: 12000, Amount: 18999},
  {Year: 2103, Storage: 20000, Amount: 31499},
  {Year: 2103, Storage: 50000, Amount: 78699},
  {Year: 2103, Storage: 100000, Amount: 157299},
  {Year: 2103, Storage: 250000, Amount: 393099},
  {Year: 2103, Storage: 500000, Amount: 786099},
  {Year: 2103, Storage: 750000, Amount: 1179099},
  {Year: 2103, Storage: 1000000, Amount: 1572099},
  {Year: 2103, Storage: 2000000, Amount: 3144199},
  {Year: 2104, Storage: 100, Amount: 299},
  {Year: 2104, Storage: 250, Amount: 499},
  {Year: 2104, Storage: 500, Amount: 899},
  {Year: 2104, Storage: 750, Amount: 1299},
  {Year: 2104, Storage: 1000, Amount: 1699},
  {Year: 2104, Storage: 2000, Amount: 3399},
  {Year: 2104, Storage: 3000, Amount: 4999},
  {Year: 2104, Storage: 5000, Amount: 8299},
  {Year: 2104, Storage: 8000, Amount: 13199},
  {Year: 2104, Storage: 12000, Amount: 19699},
  {Year: 2104, Storage: 20000, Amount: 32699},
  {Year: 2104, Storage: 50000, Amount: 81699},
  {Year: 2104, Storage: 100000, Amount: 163299},
  {Year: 2104, Storage: 250000, Amount: 407999},
  {Year: 2104, Storage: 500000, Amount: 815899},
  {Year: 2104, Storage: 750000, Amount: 1223699},
  {Year: 2104, Storage: 1000000, Amount: 1631599},
  {Year: 2104, Storage: 2000000, Amount: 3263199},
  {Year: 2105, Storage: 100, Amount: 299},
  {Year: 2105, Storage: 250, Amount: 499},
  {Year: 2105, Storage: 500, Amount: 899},
  {Year: 2105, Storage: 750, Amount: 1399},
  {Year: 2105, Storage: 1000, Amount: 1799},
  {Year: 2105, Storage: 2000, Amount: 3499},
  {Year: 2105, Storage: 3000, Amount: 5199},
  {Year: 2105, Storage: 5000, Amount: 8599},
  {Year: 2105, Storage: 8000, Amount: 13599},
  {Year: 2105, Storage: 12000, Amount: 20399},
  {Year: 2105, Storage: 20000, Amount: 33999},
  {Year: 2105, Storage: 50000, Amount: 84799},
  {Year: 2105, Storage: 100000, Amount: 169399},
  {Year: 2105, Storage: 250000, Amount: 423399},
  {Year: 2105, Storage: 500000, Amount: 846599},
  {Year: 2105, Storage: 750000, Amount: 1269899},
  {Year: 2105, Storage: 1000000, Amount: 1693099},
  {Year: 2105, Storage: 2000000, Amount: 3386099},
  {Year: 2106, Storage: 100, Amount: 299},
  {Year: 2106, Storage: 250, Amount: 499},
  {Year: 2106, Storage: 500, Amount: 999},
  {Year: 2106, Storage: 750, Amount: 1399},
  {Year: 2106, Storage: 1000, Amount: 1899},
  {Year: 2106, Storage: 2000, Amount: 3599},
  {Year: 2106, Storage: 3000, Amount: 5399},
  {Year: 2106, Storage: 5000, Amount: 8899},
  {Year: 2106, Storage: 8000, Amount: 14199},
  {Year: 2106, Storage: 12000, Amount: 21199},
  {Year: 2106, Storage: 20000, Amount: 35199},
  {Year: 2106, Storage: 50000, Amount: 87899},
  {Year: 2106, Storage: 100000, Amount: 175799},
  {Year: 2106, Storage: 250000, Amount: 439199},
  {Year: 2106, Storage: 500000, Amount: 878399},
  {Year: 2106, Storage: 750000, Amount: 1317499},
  {Year: 2106, Storage: 1000000, Amount: 1756599},
  {Year: 2106, Storage: 2000000, Amount: 3513199},
  {Year: 2107, Storage: 100, Amount: 299},
  {Year: 2107, Storage: 250, Amount: 599},
  {Year: 2107, Storage: 500, Amount: 999},
  {Year: 2107, Storage: 750, Amount: 1499},
  {Year: 2107, Storage: 1000, Amount: 1899},
  {Year: 2107, Storage: 2000, Amount: 3699},
  {Year: 2107, Storage: 3000, Amount: 5599},
  {Year: 2107, Storage: 5000, Amount: 9199},
  {Year: 2107, Storage: 8000, Amount: 14699},
  {Year: 2107, Storage: 12000, Amount: 21999},
  {Year: 2107, Storage: 20000, Amount: 36499},
  {Year: 2107, Storage: 50000, Amount: 91199},
  {Year: 2107, Storage: 100000, Amount: 182299},
  {Year: 2107, Storage: 250000, Amount: 455599},
  {Year: 2107, Storage: 500000, Amount: 911199},
  {Year: 2107, Storage: 750000, Amount: 1366699},
  {Year: 2107, Storage: 1000000, Amount: 1822299},
  {Year: 2107, Storage: 2000000, Amount: 3644499},
  {Year: 2108, Storage: 100, Amount: 299},
  {Year: 2108, Storage: 250, Amount: 599},
  {Year: 2108, Storage: 500, Amount: 999},
  {Year: 2108, Storage: 750, Amount: 1499},
  {Year: 2108, Storage: 1000, Amount: 1999},
  {Year: 2108, Storage: 2000, Amount: 3899},
  {Year: 2108, Storage: 3000, Amount: 5799},
  {Year: 2108, Storage: 5000, Amount: 9599},
  {Year: 2108, Storage: 8000, Amount: 15199},
  {Year: 2108, Storage: 12000, Amount: 22799},
  {Year: 2108, Storage: 20000, Amount: 37899},
  {Year: 2108, Storage: 50000, Amount: 94599},
  {Year: 2108, Storage: 100000, Amount: 189099},
  {Year: 2108, Storage: 250000, Amount: 472599},
  {Year: 2108, Storage: 500000, Amount: 945099},
  {Year: 2108, Storage: 750000, Amount: 1417599},
  {Year: 2108, Storage: 1000000, Amount: 1890099},
  {Year: 2108, Storage: 2000000, Amount: 3780199},
  {Year: 2109, Storage: 100, Amount: 299},
  {Year: 2109, Storage: 250, Amount: 599},
  {Year: 2109, Storage: 500, Amount: 1099},
  {Year: 2109, Storage: 750, Amount: 1599},
  {Year: 2109, Storage: 1000, Amount: 2099},
  {Year: 2109, Storage: 2000, Amount: 3999},
  {Year: 2109, Storage: 3000, Amount: 5999},
  {Year: 2109, Storage: 5000, Amount: 9899},
  {Year: 2109, Storage: 8000, Amount: 15799},
  {Year: 2109, Storage: 12000, Amount: 23599},
  {Year: 2109, Storage: 20000, Amount: 39299},
  {Year: 2109, Storage: 50000, Amount: 98099},
  {Year: 2109, Storage: 100000, Amount: 196099},
  {Year: 2109, Storage: 250000, Amount: 490099},
  {Year: 2109, Storage: 500000, Amount: 980199},
  {Year: 2109, Storage: 750000, Amount: 1470199},
  {Year: 2109, Storage: 1000000, Amount: 1960199},
  {Year: 2109, Storage: 2000000, Amount: 3920299},
  {Year: 2110, Storage: 100, Amount: 299},
  {Year: 2110, Storage: 250, Amount: 599},
  {Year: 2110, Storage: 500, Amount: 1099},
  {Year: 2110, Storage: 750, Amount: 1599},
  {Year: 2110, Storage: 1000, Amount: 2099},
  {Year: 2110, Storage: 2000, Amount: 4199},
  {Year: 2110, Storage: 3000, Amount: 6199},
  {Year: 2110, Storage: 5000, Amount: 10299},
  {Year: 2110, Storage: 8000, Amount: 16399},
  {Year: 2110, Storage: 12000, Amount: 24499},
  {Year: 2110, Storage: 20000, Amount: 40799},
  {Year: 2110, Storage: 50000, Amount: 101699},
  {Year: 2110, Storage: 100000, Amount: 203399},
  {Year: 2110, Storage: 250000, Amount: 508199},
  {Year: 2110, Storage: 500000, Amount: 1016399},
  {Year: 2110, Storage: 750000, Amount: 1524499},
  {Year: 2110, Storage: 1000000, Amount: 2032599},
  {Year: 2110, Storage: 2000000, Amount: 4065199},
  {Year: 2111, Storage: 100, Amount: 299},
  {Year: 2111, Storage: 250, Amount: 599},
  {Year: 2111, Storage: 500, Amount: 1199},
  {Year: 2111, Storage: 750, Amount: 1699},
  {Year: 2111, Storage: 1000, Amount: 2199},
  {Year: 2111, Storage: 2000, Amount: 4299},
  {Year: 2111, Storage: 3000, Amount: 6399},
  {Year: 2111, Storage: 5000, Amount: 10599},
  {Year: 2111, Storage: 8000, Amount: 16999},
  {Year: 2111, Storage: 12000, Amount: 25399},
  {Year: 2111, Storage: 20000, Amount: 42199},
  {Year: 2111, Storage: 50000, Amount: 105499},
  {Year: 2111, Storage: 100000, Amount: 210799},
  {Year: 2111, Storage: 250000, Amount: 526899},
  {Year: 2111, Storage: 500000, Amount: 1053799},
  {Year: 2111, Storage: 750000, Amount: 1580599},
  {Year: 2111, Storage: 1000000, Amount: 2107399},
  {Year: 2111, Storage: 2000000, Amount: 4214799},
  {Year: 2112, Storage: 100, Amount: 299},
  {Year: 2112, Storage: 250, Amount: 599},
  {Year: 2112, Storage: 500, Amount: 1199},
  {Year: 2112, Storage: 750, Amount: 1699},
  {Year: 2112, Storage: 1000, Amount: 2299},
  {Year: 2112, Storage: 2000, Amount: 4499},
  {Year: 2112, Storage: 3000, Amount: 6699},
  {Year: 2112, Storage: 5000, Amount: 10999},
  {Year: 2112, Storage: 8000, Amount: 17599},
  {Year: 2112, Storage: 12000, Amount: 26299},
  {Year: 2112, Storage: 20000, Amount: 43799},
  {Year: 2112, Storage: 50000, Amount: 109299},
  {Year: 2112, Storage: 100000, Amount: 218599},
  {Year: 2112, Storage: 250000, Amount: 546299},
  {Year: 2112, Storage: 500000, Amount: 1092399},
  {Year: 2112, Storage: 750000, Amount: 1638599},
  {Year: 2112, Storage: 1000000, Amount: 2184699},
  {Year: 2112, Storage: 2000000, Amount: 4369299},
  {Year: 2113, Storage: 100, Amount: 299},
  {Year: 2113, Storage: 250, Amount: 699},
  {Year: 2113, Storage: 500, Amount: 1199},
  {Year: 2113, Storage: 750, Amount: 1799},
  {Year: 2113, Storage: 1000, Amount: 2399},
  {Year: 2113, Storage: 2000, Amount: 4599},
  {Year: 2113, Storage: 3000, Amount: 6899},
  {Year: 2113, Storage: 5000, Amount: 11399},
  {Year: 2113, Storage: 8000, Amount: 18199},
  {Year: 2113, Storage: 12000, Amount: 27299},
  {Year: 2113, Storage: 20000, Amount: 45399},
  {Year: 2113, Storage: 50000, Amount: 113299},
  {Year: 2113, Storage: 100000, Amount: 226499},
  {Year: 2113, Storage: 250000, Amount: 566199},
  {Year: 2113, Storage: 500000, Amount: 1132299},
  {Year: 2113, Storage: 750000, Amount: 1698399},
  {Year: 2113, Storage: 1000000, Amount: 2264499},
  {Year: 2113, Storage: 2000000, Amount: 4528999},
  {Year: 2114, Storage: 100, Amount: 299},
  {Year: 2114, Storage: 250, Amount: 699},
  {Year: 2114, Storage: 500, Amount: 1299},
  {Year: 2114, Storage: 750, Amount: 1899},
  {Year: 2114, Storage: 1000, Amount: 2399},
  {Year: 2114, Storage: 2000, Amount: 4799},
  {Year: 2114, Storage: 3000, Amount: 7099},
  {Year: 2114, Storage: 5000, Amount: 11799},
  {Year: 2114, Storage: 8000, Amount: 18899},
  {Year: 2114, Storage: 12000, Amount: 28299},
  {Year: 2114, Storage: 20000, Amount: 46999},
  {Year: 2114, Storage: 50000, Amount: 117399},
  {Year: 2114, Storage: 100000, Amount: 234799},
  {Year: 2114, Storage: 250000, Amount: 586799},
  {Year: 2114, Storage: 500000, Amount: 1173499},
  {Year: 2114, Storage: 750000, Amount: 1760299},
  {Year: 2114, Storage: 1000000, Amount: 2346999},
  {Year: 2114, Storage: 2000000, Amount: 4693899},
  {Year: 2115, Storage: 100, Amount: 299},
  {Year: 2115, Storage: 250, Amount: 699},
  {Year: 2115, Storage: 500, Amount: 1299},
  {Year: 2115, Storage: 750, Amount: 1899},
  {Year: 2115, Storage: 1000, Amount: 2499},
  {Year: 2115, Storage: 2000, Amount: 4999},
  {Year: 2115, Storage: 3000, Amount: 7399},
  {Year: 2115, Storage: 5000, Amount: 12299},
  {Year: 2115, Storage: 8000, Amount: 19599},
  {Year: 2115, Storage: 12000, Amount: 29299},
  {Year: 2115, Storage: 20000, Amount: 48699},
  {Year: 2115, Storage: 50000, Amount: 121699},
  {Year: 2115, Storage: 100000, Amount: 243299},
  {Year: 2115, Storage: 250000, Amount: 608099},
  {Year: 2115, Storage: 500000, Amount: 1216099},
  {Year: 2115, Storage: 750000, Amount: 1824099},
  {Year: 2115, Storage: 1000000, Amount: 2432199},
  {Year: 2115, Storage: 2000000, Amount: 4864199},
  {Year: 2116, Storage: 100, Amount: 399},
  {Year: 2116, Storage: 250, Amount: 699},
  {Year: 2116, Storage: 500, Amount: 1399},
  {Year: 2116, Storage: 750, Amount: 1999},
  {Year: 2116, Storage: 1000, Amount: 2599},
  {Year: 2116, Storage: 2000, Amount: 5099},
  {Year: 2116, Storage: 3000, Amount: 7699},
  {Year: 2116, Storage: 5000, Amount: 12699},
  {Year: 2116, Storage: 8000, Amount: 20299},
  {Year: 2116, Storage: 12000, Amount: 30299},
  {Year: 2116, Storage: 20000, Amount: 50499},
  {Year: 2116, Storage: 50000, Amount: 126099},
  {Year: 2116, Storage: 100000, Amount: 252099},
  {Year: 2116, Storage: 250000, Amount: 630099},
  {Year: 2116, Storage: 500000, Amount: 1260099},
  {Year: 2116, Storage: 750000, Amount: 1890099},
  {Year: 2116, Storage: 1000000, Amount: 2520099},
  {Year: 2116, Storage: 2000000, Amount: 5040099},
  {Year: 2117, Storage: 100, Amount: 399},
  {Year: 2117, Storage: 250, Amount: 799},
  {Year: 2117, Storage: 500, Amount: 1399},
  {Year: 2117, Storage: 750, Amount: 2099},
  {Year: 2117, Storage: 1000, Amount: 2699},
  {Year: 2117, Storage: 2000, Amount: 5299},
  {Year: 2117, Storage: 3000, Amount: 7899},
  {Year: 2117, Storage: 5000, Amount: 13199},
  {Year: 2117, Storage: 8000, Amount: 20999},
  {Year: 2117, Storage: 12000, Amount: 31399},
  {Year: 2117, Storage: 20000, Amount: 52299},
  {Year: 2117, Storage: 50000, Amount: 130599},
  {Year: 2117, Storage: 100000, Amount: 261199},
  {Year: 2117, Storage: 250000, Amount: 652799},
  {Year: 2117, Storage: 500000, Amount: 1305499},
  {Year: 2117, Storage: 750000, Amount: 1958199},
  {Year: 2117, Storage: 1000000, Amount: 2610899},
  {Year: 2117, Storage: 2000000, Amount: 5221799},
  {Year: 2118, Storage: 100, Amount: 399},
  {Year: 2118, Storage: 250, Amount: 799},
  {Year: 2118, Storage: 500, Amount: 1499},
  {Year: 2118, Storage: 750, Amount: 2099},
  {Year: 2118, Storage: 1000, Amount: 2799},
  {Year: 2118, Storage: 2000, Amount: 5499},
  {Year: 2118, Storage: 3000, Amount: 8199},
  {Year: 2118, Storage: 5000, Amount: 13599},
  {Year: 2118, Storage: 8000, Amount: 21699},
  {Year: 2118, Storage: 12000, Amount: 32599},
  {Year: 2118, Storage: 20000, Amount: 54199},
  {Year: 2118, Storage: 50000, Amount: 135299},
  {Year: 2118, Storage: 100000, Amount: 270599},
  {Year: 2118, Storage: 250000, Amount: 676299},
  {Year: 2118, Storage: 500000, Amount: 1352399},
  {Year: 2118, Storage: 750000, Amount: 2028599},
  {Year: 2118, Storage: 1000000, Amount: 2704699},
  {Year: 2118, Storage: 2000000, Amount: 5409399},
  {Year: 2119, Storage: 100, Amount: 399},
  {Year: 2119, Storage: 250, Amount: 799},
  {Year: 2119, Storage: 500, Amount: 1499},
  {Year: 2119, Storage: 750, Amount: 2199},
  {Year: 2119, Storage: 1000, Amount: 2899},
  {Year: 2119, Storage: 2000, Amount: 5699},
  {Year: 2119, Storage: 3000, Amount: 8499},
  {Year: 2119, Storage: 5000, Amount: 14099},
  {Year: 2119, Storage: 8000, Amount: 22499},
  {Year: 2119, Storage: 12000, Amount: 33699},
  {Year: 2119, Storage: 20000, Amount: 56099},
  {Year: 2119, Storage: 50000, Amount: 140199},
  {Year: 2119, Storage: 100000, Amount: 280199},
  {Year: 2119, Storage: 250000, Amount: 700499},
  {Year: 2119, Storage: 500000, Amount: 1400799},
  {Year: 2119, Storage: 750000, Amount: 2101199},
  {Year: 2119, Storage: 1000000, Amount: 2801599},
  {Year: 2119, Storage: 2000000, Amount: 5603099},
  {Year: 2120, Storage: 100, Amount: 399},
  {Year: 2120, Storage: 250, Amount: 799},
  {Year: 2120, Storage: 500, Amount: 1599},
  {Year: 2120, Storage: 750, Amount: 2299},
  {Year: 2120, Storage: 1000, Amount: 2999},
  {Year: 2120, Storage: 2000, Amount: 5899},
  {Year: 2120, Storage: 3000, Amount: 8799},
  {Year: 2120, Storage: 5000, Amount: 14599},
  {Year: 2120, Storage: 8000, Amount: 23299},
  {Year: 2120, Storage: 12000, Amount: 34899},
  {Year: 2120, Storage: 20000, Amount: 58099},
  {Year: 2120, Storage: 50000, Amount: 145199},
  {Year: 2120, Storage: 100000, Amount: 290199},
  {Year: 2120, Storage: 250000, Amount: 725499},
  {Year: 2120, Storage: 500000, Amount: 1450799},
  {Year: 2120, Storage: 750000, Amount: 2176199},
  {Year: 2120, Storage: 1000000, Amount: 2901599},
  {Year: 2120, Storage: 2000000, Amount: 5803099},
  {Year: 2121, Storage: 100, Amount: 399},
  {Year: 2121, Storage: 250, Amount: 899},
  {Year: 2121, Storage: 500, Amount: 1599},
  {Year: 2121, Storage: 750, Amount: 2399},
  {Year: 2121, Storage: 1000, Amount: 3099},
  {Year: 2121, Storage: 2000, Amount: 6099},
  {Year: 2121, Storage: 3000, Amount: 9099},
  {Year: 2121, Storage: 5000, Amount: 15099},
  {Year: 2121, Storage: 8000, Amount: 24099},
  {Year: 2121, Storage: 12000, Amount: 36199},
  {Year: 2121, Storage: 20000, Amount: 60199},
  {Year: 2121, Storage: 50000, Amount: 150299},
  {Year: 2121, Storage: 100000, Amount: 300599},
  {Year: 2121, Storage: 250000, Amount: 751299},
  {Year: 2121, Storage: 500000, Amount: 1502499},
  {Year: 2121, Storage: 750000, Amount: 2253699},
  {Year: 2121, Storage: 1000000, Amount: 3004799},
  {Year: 2121, Storage: 2000000, Amount: 6009599},
  {Year: 2122, Storage: 100, Amount: 399},
  {Year: 2122, Storage: 250, Amount: 899},
  {Year: 2122, Storage: 500, Amount: 1699},
  {Year: 2122, Storage: 750, Amount: 2399},
  {Year: 2122, Storage: 1000, Amount: 3199},
  {Year: 2122, Storage: 2000, Amount: 6299},
  {Year: 2122, Storage: 3000, Amount: 9399},
  {Year: 2122, Storage: 5000, Amount: 15699},
  {Year: 2122, Storage: 8000, Amount: 24999},
  {Year: 2122, Storage: 12000, Amount: 37399},
  {Year: 2122, Storage: 20000, Amount: 62299},
  {Year: 2122, Storage: 50000, Amount: 155699},
  {Year: 2122, Storage: 100000, Amount: 311199},
  {Year: 2122, Storage: 250000, Amount: 777899},
  {Year: 2122, Storage: 500000, Amount: 1555799},
  {Year: 2122, Storage: 750000, Amount: 2333599},
  {Year: 2122, Storage: 1000000, Amount: 3111399},
  {Year: 2122, Storage: 2000000, Amount: 6222799},
  {Year: 2123, Storage: 100, Amount: 399},
  {Year: 2123, Storage: 250, Amount: 899},
  {Year: 2123, Storage: 500, Amount: 1699},
  {Year: 2123, Storage: 750, Amount: 2499},
  {Year: 2123, Storage: 1000, Amount: 3299},
  {Year: 2123, Storage: 2000, Amount: 6499},
  {Year: 2123, Storage: 3000, Amount: 9799},
  {Year: 2123, Storage: 5000, Amount: 16199},
  {Year: 2123, Storage: 8000, Amount: 25899},
  {Year: 2123, Storage: 12000, Amount: 38799},
  {Year: 2123, Storage: 20000, Amount: 64499},
  {Year: 2123, Storage: 50000, Amount: 161199},
  {Year: 2123, Storage: 100000, Amount: 322199},
  {Year: 2123, Storage: 250000, Amount: 805399},
  {Year: 2123, Storage: 500000, Amount: 1610799},
  {Year: 2123, Storage: 750000, Amount: 2416099},
  {Year: 2123, Storage: 1000000, Amount: 3221499},
  {Year: 2123, Storage: 2000000, Amount: 6442899},
  {Year: 2124, Storage: 100, Amount: 399},
  {Year: 2124, Storage: 250, Amount: 899},
  {Year: 2124, Storage: 500, Amount: 1799},
  {Year: 2124, Storage: 750, Amount: 2599},
  {Year: 2124, Storage: 1000, Amount: 3399},
  {Year: 2124, Storage: 2000, Amount: 6799},
  {Year: 2124, Storage: 3000, Amount: 10099},
  {Year: 2124, Storage: 5000, Amount: 16799},
  {Year: 2124, Storage: 8000, Amount: 26799},
  {Year: 2124, Storage: 12000, Amount: 40099},
  {Year: 2124, Storage: 20000, Amount: 66799},
  {Year: 2124, Storage: 50000, Amount: 166899},
  {Year: 2124, Storage: 100000, Amount: 333599},
  {Year: 2124, Storage: 250000, Amount: 833899},
  {Year: 2124, Storage: 500000, Amount: 1667599},
  {Year: 2124, Storage: 750000, Amount: 2501399},
  {Year: 2124, Storage: 1000000, Amount: 3335099},
  {Year: 2124, Storage: 2000000, Amount: 6670099},
]
