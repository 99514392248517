import {IMessage} from '../../../interfaces/iMessage'
import OutboxTable from './tables/outboxTable'
type Props = {
  messages?: Array<IMessage>
}

//all outbox messages have user as creator, userId as creatorId

export const OutboxLarge = ({messages}: Props): React.ReactElement => {
  const msgs = messages || []
  //date checking
  // const date = new Date();
  // console.log(DateFormatter(date, "mm-dd-yy : "));
  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <OutboxTable rows={msgs} />
      </div>
    </>
  )
}
