import {useSearchParams} from 'react-router-dom'

export default function PaymentCancel() {
  const [searchParams] = useSearchParams()
  const swipe_session = searchParams.get('session_id')
  return (
    <>
      <h1>Your order was canceled</h1>
      <p>
        Let us know if there is anything else we can help with
        <a href='mailto:orders@example.com'>orders@example.com</a>.
      </p>
    </>
  )
}
