import React from 'react'
import Footer from './footer'

export type Props = React.PropsWithChildren<{
  header?: React.ReactNode
  footer?: React.ReactNode
}>

export default function FlexHeadFoot({
  children,
  header,
  footer = <Footer />,
}: Props): React.ReactElement {
  return (
    <>
      <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
        <div>{header}</div>
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
          }}
        >
          {children}
        </div>
        <div style={{height: '60px'}}>{footer}</div>
      </div>
    </>
  )
}
