// catch() clauses use the "any" type. Turn it into an actual error object.
// TypeScript is incredibly picky about this, which strikes me as odd given that it
// is perfectly happy to accept whatever garbage comes back from an API call as any
// type you care to name.
export default function errorize(err: any) {
  if (err instanceof Error) {
    return err
  } else if (typeof err === 'string') {
    return new Error(err)
  } else {
    try {
      return new Error(JSON.stringify(err))
    } catch (doubleErr: any) {
      return new Error(`Nonstandard error object, could not stringify: ${err}`)
    }
  }
}
