import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import * as yup from 'yup'
import {SubmitButton} from '../../components/submitButton'
import useYupValidationResolver from '../../hooks/app/yupValidator'
import {usePostHookMutationAxios} from '../../hooks/usePostHookMutationAxios'
import {userFormData} from '../../interfaces/shared'
import {useAuth} from 'auth/useAuth'
import LinearProgress from '@mui/material/LinearProgress'
import {grey} from '@mui/material/colors'

const validationSchema = yup.object({
  email: yup.string().required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Required'),
  password: yup.string().required('Password is required'),
  confirmPassword: yup.string().test('passwords-match', 'Passwords must match', function (value) {
    return this.parent.password === value
  }),
})

export default function SignupForm(): React.ReactElement {
  const [, loginAuth] = useAuth()
  const [loadingSignup, setLoadingSignup] = useState<boolean>(false)
  const info = window.navigator.userAgent
  const navigate = useNavigate()

  const resolver = useYupValidationResolver(validationSchema)
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<userFormData>({resolver})

  const config = {
    headers: {
      accept: 'application/json',
      'User-Agent': 'Mozilla/5.0',
      'content-type': 'application/json',
    },
  }
  //submit rns the function here, hook is called and sets data
  //need an effet on retData to set a token and navigate
  const onSubmit = handleSubmit(async (data) => {
    setLoadingSignup(true)
    if (data) {
      await loginAuth(data, undefined, false)
      setLoadingSignup(false)
      navigate('/please-verify')
    }
  })

  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show)
  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <>
      {loadingSignup ? (
        <Box display={'flex'} flexDirection={'column'}>
          <Typography color={grey[50]} variant={'h4'}>
            processing....
          </Typography>
          <LinearProgress variant={'indeterminate'} />
        </Box>
      ) : (
        <Box
          component='form'
          // sx={{
          //   flexGrow: 1,
          //   "& .MuiTextField-root": { m: 2 },
          // }}
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#000000',
          }}
          autoComplete='off'
          onSubmit={onSubmit}
        >
          <Grid
            item
            style={{
              width: '400px',
              backgroundColor: '#eeeeee',
              paddingLeft: '1em',
              paddingRight: '2em',
              paddingTop: '2em',
              paddingBottom: '3em',
              borderRadius: '4px',
            }}
          >
            <Grid item xs={12}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant={'h5'}>Create New Account</Typography>
                <Button onClick={() => navigate('/')}>
                  <Typography variant={'body1'}>Cancel</Typography>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors?.email ? true : false}
                sx={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '4px',
                  margin: '.5em',
                  marginTop: '2em',
                }}
                fullWidth
                id='email'
                label='Email'
                {...register('email')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                sx={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '4px',
                  margin: '.5em',
                }}
                fullWidth
                id='firstName'
                label='First name'
                error={errors?.firstName ? true : false}
                {...register('firstName')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '4px',
                  margin: '.5em',
                }}
                fullWidth
                id='lastName'
                label='Last name'
                error={errors?.lastName ? true : false}
                {...register('lastName')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                sx={{
                  width: '100%',
                  backgroundColor: '#FFFFFF',
                  m: 1,
                }}
                variant='outlined'
              >
                <InputLabel htmlFor='password'>Password</InputLabel>
                <OutlinedInput
                  id='password'
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                        tabIndex={-1}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Password'
                  error={errors?.password ? true : false}
                  {...register('password')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                sx={{
                  width: '100%',
                  backgroundColor: '#FFFFFF',
                  m: 1,
                }}
                variant='outlined'
              >
                <InputLabel htmlFor='retypepassword'>Confirm password</InputLabel>
                <OutlinedInput
                  id='confirmPassword'
                  type={showConfirmPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle confirm password visibility'
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge='end'
                        tabIndex={-1}
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Confirm password'
                  error={errors?.confirmPassword ? true : false}
                  {...register('confirmPassword')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <SubmitButton
                  btntext={'Sign Up'}
                  type='submit'
                  variant='contained'
                  color={'primary'}
                  sx={{mr: 1, ml: 1, mt: 1, p: 1, fontSize: '2em'}}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}
