import Timer from './cs_components/Countdown/Timer'
import Preloader from './cs_components/Preloader/Preloader'

export default function ComingSoon() {
  return (
    <div className='comingsoon comingsoon-body'>
      <div className='comingsoon-container'>
        <p className={'comingsoon-h1'}>
          MessageUniverse
          <br />
          <span className={'comingsoon-cs'}>Coming Soon</span>
        </p>
        <Timer />
        {/* <Optin /> */}
        <Preloader />
      </div>
    </div>
  )
}
