import {useCallback, useState} from 'react'
import {apiHost} from '../config/apiConfig'
import {userFormData} from '../interfaces/shared'
import {PutDataOptions, PutDataResponse, putData} from './api/usePutDataFetch'

export default function usePutGeneric<T>(): [
  (path: string, body: any, options?: PutDataOptions<T>) => Promise<void>,
  PutDataResponse<T>
] {
  const [data, setData] = useState<T | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()

  const execPutGeneric = useCallback(
    async (path: string, body?: userFormData, options?: PutDataOptions<T>) => {
      const route = apiHost + path
      setLoading(true)
      putData<T>(route, JSON.stringify(body))
        .then((response: T) => {
          return response
        })
        .then((response) => {
          setData(response)
          setLoading(false)
          options?.onComplete?.(response)
        })
        .catch((e) => {
          setError(e)
          setLoading(false)
          options?.onError?.(e)
        })
    },
    []
  )

  return [execPutGeneric, {data, loading, error, setData}]
}
