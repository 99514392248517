import Box from '@mui/material/Box'
import React from 'react'
import LandingBar from '../../layouts/LandingBar'
import FlexHeadFoot from '../../layouts/flexheadfoot'

// const useStyles = makeStyles(() => ({
//   bar: {
//     width: '100vw',
//     paddingBottom: '2em',
//     marginBottom: '2em',
//     '& ul': {
//       'list-style-type': 'none',
//       padding: 0,
//       margin: 0,
//       background: 'black',
//     },
//     '& li a': {
//       display: 'block',
//       textDecoration: 'none',
//       color: 'white',
//       padding: '.6em 1em',
//     },
//     '& li a:hover': {
//       background: '#c4c4c4',
//     },
//   },
//   toolbar: {
//     '@media (min-width: 30em)': {
//       display: 'flex',
//       flexWrap: 'wrap',
//       justifyContent: 'space-between',
//       '& li': {
//         flex: '1 0 auto',
//         textAlign: 'center',
//       },
//     },
//   },
// }))

const Toolbar = (): React.ReactElement => {
  //const classes = useStyles()
  return (
    <>
      <Box flexGrow={1}>
        <div>
          <ul>
            <li>
              <a href='#'>Basic Info</a>
            </li>
            <li>
              <a href='#'>Schools</a>
            </li>
            <li>
              <a href='#'>Work Places</a>
            </li>
            <li>
              <a href='#'>Security Questions</a>
            </li>

            <li>
              <a href='#'>Notifications</a>
            </li>
            <li>
              <a href='#'>Privacy</a>
            </li>
          </ul>
        </div>
      </Box>
    </>
  )
}

export default function Profile(): React.ReactElement {
  const hideIT: boolean = process.env.REACT_APP_HIDE_IT === 'TRUE' ? true : false
  return (
    <FlexHeadFoot header={<LandingBar hideIT={hideIT} />}>
      <Toolbar />
    </FlexHeadFoot>
  )
}
