import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import {blue} from '@mui/material/colors'
import green from '@mui/material/colors/green'
import {FileUpload} from '../../components/upload/FileUpload'
import {usePostHookMutationAxios} from '../../hooks/usePostHookMutationAxios'
import usePostS3 from '../../hooks/usePostS3'

export default function Media() {
  const [file, setFile] = React.useState<File>()
  const [progressLoaded, setProgressLoaded] = React.useState<{
    loaded: number
  }>({loaded: 0})
  const [exec, {data, error, loading}] = usePostS3()
  const postHook = usePostHookMutationAxios<FormData>('bucket/storage')
  const [wasabiFile, setFileWasabi] = React.useState<string>()

  const handleSaveFile = async () => {
    if (file) {
      const data = new FormData()
      data.append('file', file, file.name)
      const result = await postHook.mutateAsync({
        body: data,
        progressState: setProgressLoaded,
      })
      if (result) {
        const x = 'debug'
      }
    }
  }

  const handleLoadMyImage = () => {
    exec(
      '/api/bucket/retrieve',
      {fileName: 'blackSplashScreen.png'},
      {
        onComplete: (res: any) => {
          //should get an href o file
          const b = 'check'
          setFileWasabi(b)
        },
        onError: (err: any) => alert(err),
      }
    )
  }

  return (
    <Box>
      <Typography variant={'h4'}>Media</Typography>
      <FileUpload parentSetFile={setFile} />
      <Box>{/* <Content file={fileBase64} /> */}</Box>
      <Button onClick={handleSaveFile}>Save File</Button>
      <Button variant={'outlined'} style={{color: green[800]}} onClick={handleLoadMyImage}>
        Get that Wasabi Image
      </Button>
      {wasabiFile && <img src={wasabiFile} alt='img' />}

      {progressLoaded.loaded > 0 && (
        <Box>
          <Typography variant={'h6'} color={blue[500]}>
            Uploading
          </Typography>
          <CircularProgress variant='determinate' value={progressLoaded.loaded} />
        </Box>
      )}
    </Box>
  )
}
