import {createRoot} from 'react-dom/client'
import {QueryClient, QueryClientProvider} from 'react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter, Route, Routes, To, useLocation, useNavigate} from 'react-router-dom'
import {EmailVerificationLanding} from 'routes/public/messageComponents/emailVerificationLanding'
import Login from 'routes/public/login'
import Landing from 'routes/public/landing'
import Signup from 'routes/public/signup'
import Health from 'routes/public/health'
import VerifyEmail from 'routes/public/verifyEmail'
import ForgotPasswordMessage from 'routes/public/forgotPasswordMessage'
import ForgotPassword from 'routes/public/forgotPassword'
import ResetPassword from 'routes/public/resetPassword'
import {PasswordResetSuccess} from 'routes/public/messageComponents/passwordResetSuccess'
import {PasswordResetFail} from 'routes/public/messageComponents/passwordResetFail'
import {useAuthProvider} from 'auth/AuthProvider'
import Home from 'routes/private/home'
import PublicRoute from 'routes/public'
import PrivateRoute from 'routes/private'
import Profile from 'routes/private/profile'
import Media from 'routes/private/media'
import Contacts from 'routes/private/contacts'
import Messages from 'routes/private/messages'
import Pricing from 'routes/private/Pricing'
import PaymentSuccess from 'routes/private/components/payment/PaymentSuccess'
import PaymentCancel from 'routes/private/components/payment/PaymentCancel'
import SandBox from 'routes/public/Sandbox'
import PlanProvider from 'routes/private/account/PlanProvider'
import Register from 'routes/private/account/Register'
import Prices from 'routes/private/account/Prices'
import Subscribe from 'routes/private/account/Subscribe'
import Account from 'routes/private/account/Account'
import Cancel from 'routes/private/account/Cancel'
import MuPlans from 'routes/private/account/MuPlans'
import ComingSoon from 'routes/public/ComingSoon'

export default function AppRoutes() {
  const {currentUser} = useAuthProvider()
  return (
    <Routes>
      <Route
        path='/'
        element={
          <PublicRoute>
            <ComingSoon />
          </PublicRoute>
        }
      />
      <Route
        path='/landing'
        element={
          <>
            {currentUser ? (
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            ) : (
              <PublicRoute>
                <Landing />
              </PublicRoute>
            )}
          </>
        }
      />

      <Route
        path='/login'
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route
        path='/signup'
        element={
          <PublicRoute>
            <Signup />
          </PublicRoute>
        }
      />

      <Route
        path='/health'
        element={
          <PublicRoute>
            <Health />
          </PublicRoute>
        }
      />

      <Route
        path='/forgot-message'
        element={
          <PublicRoute>
            <ForgotPasswordMessage />
          </PublicRoute>
        }
      />

      <Route
        path='/please-verify'
        element={
          <PublicRoute>
            <VerifyEmail />
          </PublicRoute>
        }
      />

      <Route
        path='/privacy'
        element={
          <PublicRoute>
            <>Privacy statement</>
          </PublicRoute>
        }
      />

      <Route
        path='/forgot-password'
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />

      <Route
        path='/reset-password/:resetCode'
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />

      <Route
        path='/reset-success'
        element={
          <PublicRoute>
            <PasswordResetSuccess />
          </PublicRoute>
        }
      />

      <Route
        path='/reset-fail'
        element={
          <PublicRoute>
            <PasswordResetFail />
          </PublicRoute>
        }
      />

      <Route
        path='/verify-email/:verificationString'
        element={
          <PublicRoute>
            <EmailVerificationLanding />
          </PublicRoute>
        }
      />
      <Route
        path='/home'
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path='/profile'
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path='/media'
        element={
          <PrivateRoute>
            <Media />
          </PrivateRoute>
        }
      />
      <Route
        path='/contacts'
        element={
          <PrivateRoute>
            <Contacts />
          </PrivateRoute>
        }
      />
      <Route
        path='/messages'
        element={
          <PrivateRoute>
            <Messages />
          </PrivateRoute>
        }
      />
      <Route
        path='/pricing'
        element={
          <PrivateRoute>
            <Pricing />
          </PrivateRoute>
        }
      />
      <Route
        path='/plans/register'
        element={
          <PrivateRoute>
            <Register />
          </PrivateRoute>
        }
      />
      <Route
        path='/plans/prices'
        element={
          <PrivateRoute>
            <Prices />
          </PrivateRoute>
        }
      />
      <Route
        path='/plans/products/subscriptions'
        element={
          <PrivateRoute>
            <MuPlans />
          </PrivateRoute>
        }
      />
      <Route
        path='/plans/subscribe'
        element={
          <PrivateRoute>
            <Subscribe />
          </PrivateRoute>
        }
      />
      <Route
        path='/plans/account'
        element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        }
      />
      <Route
        path='/plans/cancel'
        element={
          <PrivateRoute>
            <Cancel />
          </PrivateRoute>
        }
      />

      <Route
        path='/pay/success'
        element={
          <PublicRoute>
            <PaymentSuccess />
          </PublicRoute>
        }
      />
      <Route
        path='/pay/cancel'
        element={
          <PublicRoute>
            <PaymentCancel />
          </PublicRoute>
        }
      />
      <Route
        path='/sandbox'
        element={
          <PublicRoute>
            <SandBox />
          </PublicRoute>
        }
      />
    </Routes>
  )
}
