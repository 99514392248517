import {SetStateAction} from 'react'
import {useMutation} from 'react-query'
import {apiEndpoint} from '../config/apiConfig'
import {queryClient} from './api/react-query-defaults'
import {postDataAxios} from './api/usePostDataAxios'
import errorNotify from './api/utils/errorNotify'
import toastr from 'toastr'

interface HookArgs {
  body: any
  key?: string | string[]
  doInvalidate?: boolean
  progressState?: React.Dispatch<SetStateAction<{loaded: number}>>
}

export function usePostHookMutationAxios<T>(route: string, errMsg = 'Post Failed') {
  return useMutation(async (args: HookArgs) => {
    try {
      if (args.progressState) {
        const retval = await postDataAxios<T>(apiEndpoint(route), args.body, args.progressState)
        if (args.doInvalidate && args.key) {
          queryClient.invalidateQueries([args.key])
        }
        return retval
      } else {
        const retval = await postDataAxios<T>(apiEndpoint(route), args.body)
        if (args.doInvalidate && args.key) {
          queryClient.invalidateQueries([args.key])
        }
        return retval
      }
    } catch (err) {
      return undefined
      //toastr.error('Try again, something went wrong')
      //errorNotify(err, errMsg)
    }
  })
}
