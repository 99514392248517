import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import LogoBar from '../../components/logoBar'
import FlexHeadFoot from '../../layouts/flexheadfoot'

export default function ForgotPasswordMessage() {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 6000)
  }, [navigate])

  return (
    <FlexHeadFoot header={<LogoBar />}>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000000',
        }}
      >
        <Grid
          item
          style={{
            width: '50em',
            backgroundColor: '#eeeeee',
            paddingLeft: '1em',
            paddingRight: '2em',
            paddingTop: '2em',
            paddingBottom: '3em',
            borderRadius: '4px',
          }}
        >
          <Grid item xs={12}>
            <Typography variant={'h4'}>Your password reset request has been submitted</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h6'}>
              Check your email to complete resetting your password
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </FlexHeadFoot>
  )
}
