import axios from 'axios'
import {SetStateAction} from 'react'

export async function postDataAxios<TResponse = unknown>(
  path: string,
  body: FormData,
  progressState?: React.Dispatch<SetStateAction<{loaded: number}>>
): Promise<TResponse> {
  if (progressState) {
    const res = await axios.post(path, body, {
      onUploadProgress: (ProgressEvent) => {
        progressState({
          loaded: (ProgressEvent.loaded / (ProgressEvent?.total ?? 0)) * 100,
        })
      },
    })
    return res.data as unknown as TResponse
  } else {
    const res = await axios.post(path, body)
    return res.data as unknown as TResponse
  }
}

export async function postForFileAxios<TResponse = unknown>(
  path: string,
  body: any,
  progressState?: React.Dispatch<SetStateAction<{loaded: number}>>
): Promise<TResponse> {
  if (progressState) {
    const res = await axios.post(path, body, {
      onUploadProgress: (ProgressEvent) => {
        progressState({
          loaded: (ProgressEvent.loaded / (ProgressEvent?.total ?? 0)) * 100,
        })
      },
      headers: {
        accept: 'application/octet-stream',
        'User-Agent': 'Mozilla/5.0',
        'content-type': 'application/json',
      },
    })

    return res.data as unknown as TResponse
  } else {
    const res = await axios.post(path, body, {
      headers: {
        accept: 'application/octet-stream',
        'User-Agent': 'Mozilla/5.0',
        'content-type': 'application/json',
      },
    })
    return res.data as unknown as TResponse
  }
}
