import Button from '@mui/material/Button'

interface Props {
  parentSetFile: (file: File) => void
}

export const FileUpload = ({parentSetFile}: Props) => {
  const handleChange = async (newFile: File | undefined) => {
    if (newFile) {
      parentSetFile(newFile)
    }
  }
  return (
    <Button variant='contained' component='label'>
      Upload Media
      <input
        type='file'
        onChange={(e) => handleChange(e.target.files ? e.target.files[0] : undefined)}
        hidden
      />
    </Button>
  )
}
