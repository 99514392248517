import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import logo from '../assets/logo.png'

function LogoBar() {
  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position='static' sx={{backgroundColor: '#232323'}}>
        <Container maxWidth='xl'>
          <Box sx={{flexGrow: 1, display: {xs: 'flex'}}}>
            <div
              style={{
                display: 'flex',
                maxWidth: '48px',
                minWidth: '32px',
                alignItems: 'center',
              }}
            >
              <img
                src={logo}
                alt='Mu logo'
                style={{
                  display: 'flex',
                  width: '100%',
                  height: 'auto',
                }}
              />
            </div>
            <Typography
              variant='h6'
              noWrap
              component='a'
              href='/'
              sx={{
                p: '1em',
                ml: '1em',
                display: {xs: 'flex'},
                fontFamily: 'Alatsi',
                letterSpacing: '.5rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              MESSAGE UNIVERSE
            </Typography>
          </Box>
        </Container>
      </AppBar>
    </Box>
  )
}
export default LogoBar
