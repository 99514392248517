import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import LandingBar from 'layouts/LandingBar'
import {useNavigate} from 'react-router-dom'
import toastr from 'toastr'
import PricingTable from './components/payment/PricingTable'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import blue from '@mui/material/colors/blue'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import grey from '@mui/material/colors/grey'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import PaymentCheckoutPlan from './components/payment/PaymentCheckoutPlan'
import * as React from 'react'
import {styled} from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
  CURRENTGIGCOST,
  PriceCatalog,
  ShowInflationCostsPerGigabyte,
  ShowInflationCostsPerMegabyte,
  getInflationOverYerPerGigaByte,
} from 'utils/pricing'
import usePostGeneric from 'hooks/usePostGeneric'

export default function Pricing() {
  const [exec, {data, loading, error}] = usePostGeneric<any>()

  const handleMakProduct = async () => {
    exec(
      `/api/pay/product`,
      {},
      {
        onComplete: (res) => alert('made a product'),
        onError: (error: any) => {
          toastr.error('An error occurred making a product')
        },
      }
    )
  }

  const today = new Date()
  const navigate = useNavigate()
  const handleRegisterRoute = (type: string) => {
    let to = ''
    //isout plans
    if (type === 'team' || type === 'single' || type === 'custom') {
      to = 'iscout'
    }
    //free plan
    if (type === 'free') {
      to = 'free'
    }
    //free plan
    if (type === 'hci') {
      to = 'hci'
    }

    navigate(`/register?type=${to}`)
  }
  const hideIT: boolean = process.env.REACT_APP_HIDE_IT === 'TRUE' ? true : false

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const [selectedPricing, setSelectedPricing] = React.useState<
    {itemId: string; storageAmnt: number; price: number}[]
  >([])

  function createData(
    name: string,
    storage: React.ReactElement,
    year: string,
    price: string,
    cart: React.ReactElement
  ) {
    return {name, storage, year, price, cart}
  }

  const handleChangeAmount = (event: SelectChangeEvent, itemId: string, year: string) => {
    const adjStrYer = year as unknown as number
    const storageAmount = event.target.value as unknown as number
    const item = {
      itemId: itemId,
      storageAmnt: storageAmount,
      price: getInflationOverYerPerGigaByte(
        adjStrYer - today.getFullYear(),
        CURRENTGIGCOST,
        storageAmount
      ),
    }
    const found = selectedPricing?.find((f) => f.itemId === itemId)
    if (found) {
      //remove from array
      const temp = selectedPricing.filter((f) => f.itemId !== itemId)
      setSelectedPricing([...temp, item])
    } else {
      setSelectedPricing((prev) => [...prev, item])
    }
  }

  const currentMoment = moment()
  const endMoment = moment().add(100, 'year')

  const rows = []
  while (currentMoment.isBefore(endMoment, 'year')) {
    currentMoment.add(1, 'year')
    // availableYears.push(currentMoment.format('YYYY'))
    rows.push(
      createData(
        `${currentMoment.format('YYYY')}`,
        <FormControl fullWidth>
          <InputLabel id={`${currentMoment.format('YYYY')}_label`}>Storage for Year</InputLabel>
          <Select
            labelId={`${currentMoment.format('YYYY')}_label`}
            id={`${currentMoment.format('YYYY')}_id`}
            name={`${currentMoment.format('YYYY')}`}
            value={selectedPricing
              ?.find((f) => f.itemId === `${currentMoment.format('YYYY')}_id`)
              ?.storageAmnt.toString()}
            label='Select Storage Amount'
            onChange={(e: SelectChangeEvent) =>
              handleChangeAmount(e, e.target.name + '_id', e.target.name)
            }
          >
            <MenuItem value={1}>1 (gigabyte)</MenuItem>
            <MenuItem value={5}>5 (gigabytes)</MenuItem>
            <MenuItem value={10}>10 (gigabytes)</MenuItem>
            <MenuItem value={25}>25 (gigabytes)</MenuItem>
            <MenuItem value={50}>50 (gigabytes)</MenuItem>
            <MenuItem value={100}>100 (gigabytes)</MenuItem>
            <MenuItem value={200}>200 (gigabytes)</MenuItem>
            <MenuItem value={500}>500 (gigabytes)</MenuItem>
            <MenuItem value={1000}>1 (terabyte)</MenuItem>
            <MenuItem value={2000}>2 (terabytes)</MenuItem>
            <MenuItem value={3000}>3 (terabytes)</MenuItem>
            <MenuItem value={4000}>4 (terabytes)</MenuItem>
            <MenuItem value={5000}>5 (terabytes)</MenuItem>
          </Select>
        </FormControl>,
        `${currentMoment.format('YYYY')}`,
        (
          selectedPricing?.find((f) => f.itemId === `${currentMoment.format('YYYY')}_id`)?.price ??
          ''
        ).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
        <Button
          color={'success'}
          onClick={() => navigate('/signup')}
          variant={'contained'}
          size='small'
          sx={{
            color: 'black',
            textTransform: 'none',
            fontWeight: 400,
            width: '250px',
            background: '#ffd814',
            borderColor: '#fcd200',
            borderRadius: '100px',
          }}
        >
          Add To Cart
        </Button>
      )
    )
  }

  ShowInflationCostsPerGigabyte()

  const prices = () => {
    const tableRows = PriceCatalog.map((item, i) => {
      const amtAdj = item.Amount / 100
      let amtUnit = ''
      if (item.Storage >= 1000 && item.Storage < 1000000) {
        amtUnit = item.Storage / 1000 + 'GB'
      } else if (item.Storage >= 1000000) {
        amtUnit = item.Storage / 1000000 + 'TB'
      } else {
        amtUnit = item.Storage + 'MB'
      }
      return (
        <tr key={`${i}`}>
          <td>{item.Year}</td>
          <td>{amtUnit}</td>
          <td>{item.Storage}</td>
          <td>
            {amtAdj.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </td>
        </tr>
      )
    })
    return (
      <>
        <table>
          <tbody>
            <tr>
              <th>Year</th>
              <th>Storage in MB</th>
              <th>Price</th>
            </tr>
            {tableRows}
          </tbody>
        </table>
      </>
    )
  }

  return (
    // <Box>
    //   <Box sx={{minHeight: 'calc(100vh - 70px'}}>
    //     <LandingBar hideIT={hideIT} />
    //   </Box>
    //   <Box maxWidth={'1320px'} margin={'auto'}>
    //     <TableContainer component={Paper}>
    //       <Table sx={{minWidth: 700}} aria-label='customized table'>
    //         <TableHead>
    //           <TableRow>
    //             <StyledTableCell>Subscription Package Extensions</StyledTableCell>
    //             <StyledTableCell align='right'>Storage</StyledTableCell>
    //             <StyledTableCell align='right'>Sending Year(s)</StyledTableCell>
    //             <StyledTableCell align='right'>Price</StyledTableCell>
    //             <StyledTableCell align='right'>Cart</StyledTableCell>
    //           </TableRow>
    //         </TableHead>
    //         <TableBody>
    //           {rows.map((row) => (
    //             <StyledTableRow key={row.name}>
    //               <StyledTableCell component='th' scope='row'>
    //                 {row.name}
    //               </StyledTableCell>
    //               <StyledTableCell align='right'>{row.storage}</StyledTableCell>
    //               <StyledTableCell align='right'>{row.year}</StyledTableCell>
    //               <StyledTableCell align='right'>{row.price}</StyledTableCell>
    //               <StyledTableCell align='right'>{row.cart}</StyledTableCell>
    //             </StyledTableRow>
    //           ))}
    //         </TableBody>
    //       </Table>
    //     </TableContainer>
    //   </Box>

    //   <PaymentCheckoutPlan plan={{items: []}} />
    // </Box>

    <div className='App'>
      <Button onClick={handleMakProduct}>Make Product Try</Button>
      {prices()}
    </div>
  )
}
