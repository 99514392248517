import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import toastr from 'toastr'
import * as yup from 'yup'
import useYupValidationResolver from '../../hooks/app/yupValidator'
import usePutGeneric from '../../hooks/usePutGeneric'
import {userFormData} from '../../interfaces/shared'
import {SubmitButton} from '../submitButton'

const validationSchema = yup.object({
  email: yup.string().required('Email is required'),
})

export default function ForgotPasswordForm(): React.ReactElement {
  const navigate = useNavigate()
  const [exec, {data, loading, error}] = usePutGeneric<any>()
  const resolver = useYupValidationResolver(validationSchema)
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<userFormData>({resolver})

  const onSubmit = handleSubmit((data) => {
    if (data.email) {
      //put request with email, api sends email with code, this navigates to a user message then goes home, or it errors
      exec(
        `/api/auth/reset/${data.email}`,
        {},
        {
          onComplete: () => navigate('/forgot-message'),
          onError: () => {
            toastr.error('An error occurred, please try again later')
          },
        }
      )
    }
  })

  return (
    <Box
      component='form'
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000000',
      }}
      autoComplete='off'
      onSubmit={onSubmit}
    >
      <Grid
        item
        style={{
          width: '400px',
          backgroundColor: '#eeeeee',
          paddingLeft: '1em',
          paddingRight: '2em',
          paddingTop: '2em',
          paddingBottom: '3em',
          borderRadius: '4px',
        }}
      >
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant={'h5'}>Password Reset</Typography>
            <Button onClick={() => navigate('/')}>
              <Typography variant={'body1'}>Cancel</Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors?.email ? true : false}
            sx={{
              backgroundColor: '#FFFFFF',
              borderRadius: '4px',
              margin: '.5em',
              marginTop: '2em',
            }}
            fullWidth
            id='email'
            label='Email'
            {...register('email')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <SubmitButton
              btntext={'Resend Password'}
              type='submit'
              variant='contained'
              color={'primary'}
              sx={{mr: 1, ml: 1, mt: 1, p: 1, fontSize: '1em'}}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}
