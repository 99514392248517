import {BasicModal} from './basicModal'

export const hocBasicModal = (Component: any): any => {
  return (props: any): any => {
    return (
      <>
        <BasicModal
          modalOpen={props.modalOpen}
          onRequestClose={() => props.setModalOpen(false)}
          {...props}
        >
          <Component {...props} />;
        </BasicModal>
      </>
    )
  }
}
